import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <main className="container">
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>Page not found | feedbee</title>
            </Helmet>
            <div className="container-wave">
                <div className="coast">
                    <div className="wave-rel-wrap">
                        <div className="wave" />
                    </div>
                </div>
                <div className="coast delay">
                    <div className="wave-rel-wrap">
                        <div className="wave delay" />
                    </div>
                </div>
                <div className="text text-a">4</div>
                <div className="text text-b">0</div>
                <div className="text text-c">4</div>
                <h3>Ups... This page doesn't exist or has been removed.</h3>
            </div>
        </main>
    );
};

export default NotFound;
