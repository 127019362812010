import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import logo from '../../assets/logo.png';
import logoLight from '../../assets/logo-light.png';
import isDarkColor from 'is-dark-color';

class GeometricPreview extends Component {
    constructor(props) {
        super(props);

        this.state = { overflow: false };
    }

    render() {
        const options = ['strongly disagree', 'disagree', 'neutral', 'agree', 'strongly agree'];

        return (
            <div>
                <div
                    style={{ backgroundColor: this.props.background }}
                    className="geometric-preview-container"
                >
                    <NavLink
                        className=""
                        to="/#start"
                        className="brand"
                        style={{ backgroundColor: this.props.background }}
                    >
                        <img
                            src={isDarkColor(this.props.background) ? logoLight : logo}
                            className="logo"
                            alt="logo"
                            style={{ height: '22px' }}
                        />
                    </NavLink>

                    <div className="content-wrapper">
                        <div className="content">
                            <div className="content-left">
                                <div
                                    className="description"
                                    style={{
                                        color: this.props.descriptionColor,
                                        fontFamily: this.props.descriptionFont,
                                        fontWeight: this.props.fontWeight,
                                    }}
                                >
                                    {!this.props.description && !this.props.hidePlaceholder
                                        ? '[Space for your introduction. Write whatever you want.]'
                                        : this.props.description}
                                </div>

                                <div className="survey">
                                    {Array.from(this.props.surveyQuestions.values()).map(
                                        (question, index) => (
                                            <div key={index}>
                                                {this.props.isQuestionValid(question) ? (
                                                    <div className="survey-container">
                                                        <div
                                                            className="survey-question"
                                                            style={{
                                                                color: this.props.textColor,
                                                                fontFamily: this.props.questionFont,
                                                            }}
                                                        >
                                                            {question}
                                                        </div>
                                                        <div>
                                                            <button
                                                                style={{
                                                                    borderColor: this.props.buttonColor,
                                                                    color: this.props.buttonColor,
                                                                    borderRadius: this.props.radius,
                                                                }}
                                                            >
                                                                yes
                                                            </button>
                                                            <button
                                                                style={{
                                                                    borderColor: this.props.buttonColor,
                                                                    color: this.props.buttonColor,
                                                                    borderRadius: this.props.radius,
                                                                }}
                                                            >
                                                                no
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )
                                    )}

                                    {this.props.openQuestions.size > 0 ||
                                    this.props.surveyQuestions.size > 0 ||
                                    this.props.closedQuestions.size > 0 ? null : (
                                        <div className="survey-container">
                                            <div
                                                className="survey-question"
                                                style={{
                                                    color: this.props.textColor,
                                                    fontFamily: this.props.questionFont,
                                                }}
                                            >
                                                [Space for your yes/no question.]
                                            </div>
                                            <div>
                                                <button
                                                    style={{
                                                        borderColor: this.props.buttonColor,
                                                        color: this.props.buttonColor,
                                                        borderRadius: this.props.radius,
                                                    }}
                                                >
                                                    yes
                                                </button>
                                                <button
                                                    style={{
                                                        borderColor: this.props.buttonColor,
                                                        color: this.props.buttonColor,
                                                        borderRadius: this.props.radius,
                                                    }}
                                                >
                                                    no
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {Array.from(this.props.closedQuestions.values()).map(
                                        (question, index) => (
                                            <div key={index}>
                                                {this.props.isQuestionValid(question) ? (
                                                    <div className="survey-container">
                                                        <div
                                                            className="survey-question"
                                                            style={{
                                                                color: this.props.textColor,
                                                                fontFamily: this.props.questionFont,
                                                            }}
                                                        >
                                                            {question}
                                                        </div>

                                                        <div className="cntr">
                                                            {options.map((option, index) => (
                                                                <label className="btn-radio" key={index}>
                                                                    <input
                                                                        type="radio"
                                                                        value={option}
                                                                        name={question}
                                                                    />
                                                                    <svg
                                                                        width="14px"
                                                                        height="14px"
                                                                        viewBox="0 0 20 20"
                                                                    >
                                                                        <circle
                                                                            cx="10"
                                                                            cy="10"
                                                                            r="9"
                                                                            style={{
                                                                                stroke: this.props
                                                                                    .buttonColor,
                                                                            }}
                                                                        />
                                                                        <path
                                                                            d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                                                                            className="inner"
                                                                            style={{
                                                                                stroke: this.props
                                                                                    .buttonColor,
                                                                            }}
                                                                        />
                                                                        <path
                                                                            d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                                                                            className="outer"
                                                                            style={{
                                                                                stroke: this.props
                                                                                    .buttonColor,
                                                                            }}
                                                                        />
                                                                    </svg>
                                                                    <span
                                                                        style={{
                                                                            color: this.props.textColor,
                                                                            fontFamily:
                                                                                this.props.questionFont,
                                                                        }}
                                                                    >
                                                                        {option}
                                                                    </span>
                                                                </label>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )
                                    )}

                                    {this.props.openQuestions.size > 0 ||
                                    this.props.surveyQuestions.size > 0 ||
                                    this.props.closedQuestions.size > 0 ? null : (
                                        <div className="survey-container">
                                            <div
                                                className="survey-question"
                                                style={{
                                                    color: this.props.textColor,
                                                    fontFamily: this.props.questionFont,
                                                }}
                                            >
                                                [Space for your close-ended question.]
                                            </div>
                                            <div className="cntr">
                                                {options.map((option, index) => (
                                                    <label className="btn-radio" key={index}>
                                                        <input
                                                            type="radio"
                                                            value={option}
                                                            name="opinion"
                                                        />
                                                        <svg
                                                            width="14px"
                                                            height="14px"
                                                            viewBox="0 0 20 20"
                                                        >
                                                            <circle
                                                                cx="10"
                                                                cy="10"
                                                                r="9"
                                                                style={{
                                                                    stroke: this.props.buttonColor,
                                                                }}
                                                            />
                                                            <path
                                                                d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                                                                className="inner"
                                                                style={{
                                                                    stroke: this.props.buttonColor,
                                                                }}
                                                            />
                                                            <path
                                                                d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                                                                className="outer"
                                                                style={{
                                                                    stroke: this.props.buttonColor,
                                                                }}
                                                            />
                                                        </svg>
                                                        <span
                                                            style={{
                                                                color: this.props.textColor,
                                                                fontFamily: this.props.questionFont,
                                                            }}
                                                        >
                                                            {option}
                                                        </span>
                                                    </label>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {Array.from(this.props.openQuestions.values()).map(
                                        (question, index) => (
                                            <div key={index}>
                                                {this.props.isQuestionValid(question) ? (
                                                    <div className="survey-container open-container">
                                                        <div
                                                            className="survey-question open-question"
                                                            style={{
                                                                color: this.props.textColor,
                                                                fontFamily: this.props.questionFont,
                                                            }}
                                                        >
                                                            {question}
                                                        </div>
                                                        <textarea
                                                            type="text"
                                                            name="answer"
                                                            className="open-input"
                                                            style={{
                                                                borderColor: this.props.buttonColor,
                                                                color: this.props.textColor,
                                                                borderRadius:
                                                                    this.props.radius === '50px'
                                                                        ? '10px'
                                                                        : this.props.radius,
                                                                fontFamily: this.props.questionFont,
                                                            }}
                                                            placeholder="your answer"
                                                            value={this.state.message}
                                                            onChange={(e) =>
                                                                this._handleQuestionAnswer(
                                                                    question.num,
                                                                    e.target.value
                                                                )
                                                            }
                                                            rows={3}
                                                            required
                                                        />
                                                    </div>
                                                ) : null}
                                            </div>
                                        )
                                    )}

                                    {this.props.openQuestions.size > 0 ||
                                    this.props.surveyQuestions.size > 0 ||
                                    this.props.closedQuestions.size > 0 ? null : (
                                        <div className="survey-container open-container">
                                            <div
                                                className="survey-question open-question"
                                                style={{
                                                    color: this.props.textColor,
                                                    fontFamily: this.props.questionFont,
                                                }}
                                            >
                                                [Space for your open-ended question.]
                                            </div>
                                            <textarea
                                                type="text"
                                                name="answer"
                                                className="open-input"
                                                style={{
                                                    borderColor: this.props.buttonColor,
                                                    color: this.props.textColor,
                                                    borderRadius:
                                                        this.props.radius === '50px'
                                                            ? '10px'
                                                            : this.props.radius,
                                                    fontFamily: this.props.questionFont,
                                                }}
                                                placeholder="your answer"
                                                value={this.state.message}
                                                onChange={(e) =>
                                                    this._handleQuestionAnswer(
                                                        question.num,
                                                        e.target.value
                                                    )
                                                }
                                                rows={3}
                                                required
                                            />
                                        </div>
                                    )}

                                    <button
                                        className="submit-btn"
                                        style={{
                                            borderColor: this.props.buttonColor,
                                            backgroundColor: this.props.buttonColor,
                                            color: isDarkColor(this.props.buttonColor)
                                                ? '#ffffff'
                                                : '#000000',
                                            borderRadius: this.props.radius,
                                        }}
                                    >
                                        submit your answers
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            className="footer-decor"
                            style={{
                                backgroundImage: `linear-gradient(-45deg, ${this.props.backgroundTwo} 14px, transparent 0),
                            linear-gradient(45deg, ${this.props.backgroundTwo} 14px, transparent 0)`,
                            }}
                        ></div>

                        <div
                            className="template-footer"
                            style={{ backgroundColor: this.props.backgroundTwo }}
                        >
                            <div
                                style={{
                                    color: isDarkColor(this.props.backgroundTwo) ? '#fcfbfa' : '#0b0e11',
                                }}
                            >
                                Powered by{' '}
                                <Link
                                    to="/"
                                    style={{
                                        color: isDarkColor(this.props.backgroundTwo)
                                            ? '#fcfbfa'
                                            : '#0b0e11',
                                    }}
                                >
                                    feedbee
                                </Link>
                                .
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _handleChange = (key, value) => {
        this.setState({ [key]: value });
    };
}

export default GeometricPreview;
