import React, { Component } from 'react';
import api from '../utils/api';
import Simple from './Simple';
import BlackWhite from './BlackWhite';
import Image from './Image';
import ThreeColors from './ThreeColors';
import Geometric from './Geometric';
import { Helmet } from 'react-helmet';
import NotFound from '../NotFound';
import Cookies from 'universal-cookie';

class Templates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: '',
            error: '',
            cookieExists: false,
        };
    }

    componentDidMount() {
        //get cookies
        const cookies = new Cookies();
        const cookieObj = cookies.get('360form');

        let isThereCookie;

        if (cookieObj) {
            //check if cookie array includes page id
            isThereCookie = Object.values(cookieObj).includes(this.props.id);
        }

        if (isThereCookie) {
            this.setState({
                cookieExists: true,
            });
        }

        this._getPage();
    }

    render() {
        let props;
        let page;

        if (this.state.page) {
            page = this.state.page;

            props = {
                id: page._id,
                user: page.user,
                published: page.published,
                descriptionColor: page.descriptionColor,
                textColor: page.textColor,
                buttonColor: page.buttonColor,
                name: page.name,
                description: page.description,
                graphics: page.graphics,
                background: page.background,
                backgroundTwo: page.backgroundTwo,
                descriptionFont: page.descriptionFont,
                questionFont: page.questionFont,
                radius: page.radius,
                fontWeight: page.fontWeight,
                surveyQuestions: page.surveyQuestions,
                openQuestions: page.openQuestions,
                closedQuestions: page.closedQuestions,
                getUser: this._getUser,
                cookieExists: this.state.cookieExists,
            };
        }

        return (
            <div>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div>
                    {this.state.page.template === 'simple' ? <Simple {...props} /> : null}

                    {this.state.page.template === 'b&w' ? <BlackWhite {...props} /> : null}

                    {this.state.page.template === 'image' ? <Image {...props} /> : null}

                    {this.state.page.template === 'colors' ? <ThreeColors {...props} /> : null}

                    {this.state.page.template === 'zigzag' ? <Geometric {...props} /> : null}
                </div>
            </div>
        );
    }

    _getPage = () => {
        api.get(`/api/page/getOne/${this.props.id}`)
            .then((data) => {
                this.setState({
                    page: data.page,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _getUser = () => {
        return new Promise((resolve, reject) => {
            api.get(`/api/page/getUser/${this.state.page.user}`)
                .then((data) => {
                    resolve(data.isSubscribed);
                })
                .catch((err) => {
                    reject(err.description);
                });
        });
    };
}

export default Templates;
