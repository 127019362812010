import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import { loadStripe } from '@stripe/stripe-js';
import api from '../utils/api';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY);

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pages: [],
            error: '',
        };
    }

    componentDidMount() {
        if (this.props.user) this._getPages();

        //refreshing subscription and then user to be sure that status is correct
        this._refreshSubscription();
    }

    render() {
        if (!this.props.user) return <Redirect to="/auth/sign-in" />;

        return (
            <main className="user-container container">
                <Helmet>
                    <title>Dashboard | feedbee</title>
                </Helmet>
                <div className="card">
                    {
                        //  if user is subscribed show create page button
                        //  if user is not subscribed and has no pages show create page button

                        (!this.props.user.isSubscribed && !this.state.pages.length >= 1) ||
                        this.props.user.isSubscribed ? (
                            <button style={{ fontWeight: 'bold', border: 'none' }}>
                                <Link to="/create">
                                    <FontAwesomeIcon icon={faPlus} className="icon" />
                                    create a new page
                                </Link>
                            </button>
                        ) : null
                    }

                    {
                        //  if user is not subscribed and has pages show upgrade button

                        !this.props.user.isSubscribed && this.state.pages.length >= 1 ? (
                            <Link className="upgrade-user" to="/account">
                                you are required to upgrade your account to create more pages.
                            </Link>
                        ) : null
                    }

                    {/* { (!this.props.user.isSubscribed && this.state.pages.length >= 1) ? null : (
                        <button style={{ fontWeight: 'bold', border: 'none' }}>
                            <Link to="/create">
                                <FontAwesomeIcon icon={faPlus} className="icon" />
                                create a new page
                            </Link>
                        </button>
                    )} */}

                    {/* {!this.props.user.isSubscribed && this.props.user.isComplete ? (
                        <Link className="upgrade-user" to="/account">
                            you are currently unsubscribed.
                            <br />
                            to use the service again, restart your subscription.
                        </Link>
                    ) : null}

                    {!this.props.user.isSubscribed && !this.props.user.isComplete ? (
                        <div className="upgrade-user">
                            you are currently unsubscribed.
                            <br />
                            to use the service, please add a valid payment method.
                            <button className="add-payment" onClick={this._completePayment}>
                                add payment method
                            </button>
                        </div>
                    ) : null} */}
                </div>

                {this.state.pages.map((el) => (
                    <div className="card" key={el._id}>
                        <div className="page-name">{el.name}</div>
                        <div>answers: {el.answers}</div>
                        {!el.published ? <div className="publish-info">unpublished</div> : null}
                        <button className="details-btn safari-margin">
                            <Link to={`/dashboard/${el._id}`}>see details</Link>
                        </button>
                    </div>
                ))}
            </main>
        );
    }

    _getPages = () => {
        api.get(`/api/page/get/${this.props.user._id}`)
            .then((data) => {
                this.setState({
                    pages: data.pages,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _refreshSubscription = () => {
        api.post('/api/payment/refresh-subscription').then((data) => {
            this._refreshUser();
        });
    };

    _refreshUser = () => {
        api.post('/api/auth/refresh')
            .then((data) => {
                localStorage.setItem('identity', data.token);
                this.props.setUser();
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _completePayment = () => {
        api.post('/api/auth/complete-payment').then((data) => {
            let sessionId = data.session;
            this._handleStripeCheckout(sessionId);
        });
    };

    _handleStripeCheckout = (sessionId) => {
        stripePromise.then((stripe) => {
            stripe.redirectToCheckout({
                sessionId: sessionId,
            });
        });
    };
}

export default Dashboard;
