import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import articles from './articles.json';

class Article extends Component {
    render() {
        let colors = [
            '#ceac03',
            '#bfb09b',
            '#799da8',
            '#3d4950',
            '#799da8',
            '#ceac03',
            '#3d4950',
            '#bfb09b',
            '#3d4950',
            '#799da8',
            '#bfb09b',
            '#ceac03',
            '#bfb09b',
            '#ceac03',
            '#3d4950',
            '#799da8',
            '#3d4950',
        ];

        return (
            <div className="articles">
                {articles.articles.map((el, index) => (
                    <div className="snippet" key={index}>
                        <div className="title">{el.title}</div>

                        <div className="bottom-container">
                            <div className="content">{el.contentTop}</div>
                            <Link
                                to={`/blog/${el.link}`}
                                className="blog-btn"
                                aria-label="link to article"
                                style={{ backgroundColor: `${colors[index]}` }}
                            >
                                <div>read more</div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default Article;
