import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import NotFound from '../NotFound';
import isDarkColor from 'is-dark-color';
import { Helmet } from 'react-helmet';
import api from '../utils/api';
import logo from '../../assets/logo.png';
import logoLight from '../../assets/logo-light.png';
import Cookies from 'universal-cookie';

class Simple extends Component {
    constructor(props) {
        super(props);

        this.el = React.createRef();

        this.state = {
            surveyAnswers: new Map([]),
            openQuestionsAnswers: new Map([]),
            closedQuestionsAnswers: new Map([]),
            surveySubmitted: false,
            isLoaded: false,
        };
    }

    componentDidMount() {
        this.props
            .getUser()
            .then((data) => {
                this.setState({
                    isSubscribed: data,
                    isLoaded: true,
                });
            })
            .catch((err) => {
                this.setState({
                    err,
                });
            });
    }

    render() {
        const options = ['stronglyDisagree', 'disagree', 'neutral', 'agree', 'stronglyAgree'];

        return (
            <div>
                <Helmet>
                    <title>Feedback | feedbee</title>
                </Helmet>

                {this.state.isLoaded ? (
                    <div>
                        {this.props.published ? (
                            <div
                                style={{ backgroundColor: this.props.background }}
                                className="simple-container"
                            >
                                <div className="brand">
                                    <NavLink className="" to="/#start">
                                        <img
                                            src={isDarkColor(this.props.background) ? logoLight : logo}
                                            className="logo"
                                            alt="logo"
                                        />
                                    </NavLink>
                                </div>

                                {!this.state.surveySubmitted && !this.props.cookieExists ? (
                                    <div className="content-wrapper">
                                        {this.props.description ? (
                                            <div className="content">
                                                <div className="content-right">
                                                    <div
                                                        className="description"
                                                        style={{
                                                            color: this.props.descriptionColor,
                                                            fontFamily: this.props.descriptionFont,
                                                            fontWeight: this.props.fontWeight,
                                                        }}
                                                    >
                                                        {this.props.description}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}

                                        <div className="survey">
                                            {this.props.surveyQuestions
                                                ? Array.from(this.props.surveyQuestions.values()).map(
                                                      (question, index) => (
                                                          <div className="survey-container" key={index}>
                                                              <div
                                                                  className="survey-question"
                                                                  style={{
                                                                      color: this.props.textColor,
                                                                      fontFamily:
                                                                          this.props.questionFont,
                                                                  }}
                                                              >
                                                                  {question.question}
                                                              </div>
                                                              <div className="btns-wrapper">
                                                                  <button
                                                                      onClick={() =>
                                                                          this._handleSurveyClick(
                                                                              'yes',
                                                                              question.num
                                                                          )
                                                                      }
                                                                      style={{
                                                                          borderColor:
                                                                              this.props.buttonColor,
                                                                          borderRadius:
                                                                              this.props.radius,
                                                                          backgroundColor:
                                                                              this.state.surveyAnswers.get(
                                                                                  question.num
                                                                              ) === 'yes'
                                                                                  ? this.props
                                                                                        .buttonColor
                                                                                  : null,
                                                                          color:
                                                                              this.state.surveyAnswers.get(
                                                                                  question.num
                                                                              ) === 'yes'
                                                                                  ? isDarkColor(
                                                                                        this.props
                                                                                            .buttonColor
                                                                                    )
                                                                                      ? '#ffffff'
                                                                                      : '#000000'
                                                                                  : this.props
                                                                                        .buttonColor,
                                                                      }}
                                                                  >
                                                                      yes
                                                                  </button>
                                                                  <button
                                                                      onClick={() =>
                                                                          this._handleSurveyClick(
                                                                              'no',
                                                                              question.num
                                                                          )
                                                                      }
                                                                      style={{
                                                                          borderColor:
                                                                              this.props.buttonColor,
                                                                          borderRadius:
                                                                              this.props.radius,
                                                                          backgroundColor:
                                                                              this.state.surveyAnswers.get(
                                                                                  question.num
                                                                              ) === 'no'
                                                                                  ? this.props
                                                                                        .buttonColor
                                                                                  : null,

                                                                          color:
                                                                              this.state.surveyAnswers.get(
                                                                                  question.num
                                                                              ) === 'no'
                                                                                  ? isDarkColor(
                                                                                        this.props
                                                                                            .buttonColor
                                                                                    )
                                                                                      ? '#ffffff'
                                                                                      : '#000000'
                                                                                  : this.props
                                                                                        .buttonColor,
                                                                      }}
                                                                  >
                                                                      no
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      )
                                                  )
                                                : null}

                                            {this.props.closedQuestions
                                                ? Array.from(this.props.closedQuestions.values()).map(
                                                      (question, index) => (
                                                          <div className="survey-container" key={index}>
                                                              <div
                                                                  className="survey-question"
                                                                  style={{
                                                                      color: this.props.textColor,
                                                                      fontFamily:
                                                                          this.props.questionFont,
                                                                  }}
                                                              >
                                                                  {question.question}
                                                              </div>

                                                              <div className="btns-wrapper radio-wrapper">
                                                                  {options.map((option, index) => (
                                                                      <label
                                                                          className="btn-radio"
                                                                          key={index}
                                                                      >
                                                                          <input
                                                                              type="radio"
                                                                              value={option}
                                                                              name={question.num}
                                                                              checked={
                                                                                  this.state.closedQuestionsAnswers.get(
                                                                                      question.num
                                                                                  ) === option
                                                                              }
                                                                              onChange={(e) =>
                                                                                  this._onRadioClick(
                                                                                      e.target.value,
                                                                                      question.num
                                                                                  )
                                                                              }
                                                                          />
                                                                          <svg
                                                                              width="20px"
                                                                              height="20px"
                                                                              viewBox="0 0 20 20"
                                                                          >
                                                                              <circle
                                                                                  cx="10"
                                                                                  cy="10"
                                                                                  r="9"
                                                                                  style={{
                                                                                      stroke: this.props
                                                                                          .buttonColor,
                                                                                  }}
                                                                              />
                                                                              <path
                                                                                  d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
                                                                                  className="inner"
                                                                                  style={{
                                                                                      stroke: this.props
                                                                                          .buttonColor,
                                                                                  }}
                                                                              />
                                                                              <path
                                                                                  d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
                                                                                  className="outer"
                                                                                  style={{
                                                                                      stroke: this.props
                                                                                          .buttonColor,
                                                                                  }}
                                                                              />
                                                                          </svg>
                                                                          <span
                                                                              className="radio-label"
                                                                              style={{
                                                                                  color: this.props
                                                                                      .textColor,
                                                                                  fontFamily:
                                                                                      this.props
                                                                                          .questionFont,
                                                                              }}
                                                                          >
                                                                              {option ===
                                                                              'stronglyDisagree'
                                                                                  ? 'strongly disagree'
                                                                                  : option ===
                                                                                    'stronglyAgree'
                                                                                  ? 'strongly agree'
                                                                                  : option}
                                                                          </span>
                                                                      </label>
                                                                  ))}
                                                              </div>
                                                          </div>
                                                      )
                                                  )
                                                : null}

                                            {this.props.openQuestions
                                                ? Array.from(this.props.openQuestions.values()).map(
                                                      (question, index) => (
                                                          <div
                                                              className="survey-container open-container"
                                                              key={index}
                                                          >
                                                              <div
                                                                  className="survey-question open-question"
                                                                  style={{
                                                                      color: this.props.textColor,
                                                                      fontFamily:
                                                                          this.props.questionFont,
                                                                  }}
                                                              >
                                                                  {question.question}
                                                              </div>
                                                              <textarea
                                                                  type="text"
                                                                  name="answer"
                                                                  className="open-input"
                                                                  style={{
                                                                      borderColor:
                                                                          this.props.buttonColor,
                                                                      color: this.props.textColor,
                                                                      fontFamily:
                                                                          this.props.questionFont,
                                                                      borderRadius:
                                                                          this.props.radius === '50px'
                                                                              ? '15px'
                                                                              : this.props.radius,
                                                                  }}
                                                                  placeholder="your answer"
                                                                  value={this.state.message}
                                                                  onChange={(e) =>
                                                                      this._handleQuestionAnswer(
                                                                          question.num,
                                                                          e.target.value
                                                                      )
                                                                  }
                                                                  rows={3}
                                                                  required
                                                              />
                                                          </div>
                                                      )
                                                  )
                                                : null}

                                            <button
                                                className="submit-btn"
                                                disabled={
                                                    this.state.surveyAnswers.size > 0 ||
                                                    this.state.openQuestionsAnswers.size > 0 ||
                                                    this.state.closedQuestionsAnswers.size > 0
                                                        ? null
                                                        : 'disabled'
                                                }
                                                onClick={() => this._handleAnswersSubmit()}
                                                style={{
                                                    borderColor: this.props.buttonColor,
                                                    backgroundColor: this.props.buttonColor,
                                                    color: isDarkColor(this.props.buttonColor)
                                                        ? '#ffffff'
                                                        : '#000000',
                                                    borderRadius: this.props.radius,
                                                }}
                                            >
                                                submit your answers
                                            </button>
                                        </div>
                                        <div
                                            className="footer"
                                            style={{
                                                color: isDarkColor(this.props.background)
                                                    ? '#fcfbfa'
                                                    : '#0b0e11',
                                            }}
                                        >
                                            Powered by{' '}
                                            <Link
                                                to="/"
                                                style={{
                                                    color: isDarkColor(this.props.background)
                                                        ? '#fcfbfa'
                                                        : '#0b0e11',
                                                }}
                                            >
                                                feedbee
                                            </Link>
                                            .
                                        </div>
                                    </div>
                                ) : (
                                    <div className="content-wrapper">
                                        <div
                                            className="submit-txt"
                                            style={{
                                                color: this.props.descriptionColor,
                                                fontFamily: this.props.descriptionFont,
                                                fontWeight: this.props.fontWeight,
                                            }}
                                        >
                                            Thank you for submitting your answers!
                                        </div>
                                        <div
                                            className="footer"
                                            style={{
                                                color: isDarkColor(this.props.background)
                                                    ? '#fcfbfa'
                                                    : '#0b0e11',
                                            }}
                                        >
                                            Powered by{' '}
                                            <Link
                                                to="/"
                                                style={{
                                                    color: isDarkColor(this.props.background)
                                                        ? '#fcfbfa'
                                                        : '#0b0e11',
                                                }}
                                            >
                                                feedbee
                                            </Link>
                                            .
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <NotFound />
                        )}
                    </div>
                ) : null}
            </div>
        );
    }

    _onRadioClick = (opinion, num) => {
        let closedAnswersMap = this.state.closedQuestionsAnswers;

        closedAnswersMap.set(num, opinion);

        this.setState({
            closedQuestionsAnswers: closedAnswersMap,
        });
    };

    _handleSurveyClick = (type, num) => {
        let surveyMap = this.state.surveyAnswers;
        surveyMap.set(num, type);

        this.setState({
            surveyAnswers: surveyMap,
        });
    };

    _handleQuestionAnswer = (num, answer) => {
        let openAnswersMap = this.state.openQuestionsAnswers;
        openAnswersMap.set(num, answer);

        this.setState({
            openQuestionsAnswers: openAnswersMap,
        });
    };

    _handleAnswersSubmit = () => {
        let surveyObj = Array.from(this.state.surveyAnswers).map(([num, answer]) => ({
            [num]: answer,
        }));

        let openObj = Array.from(this.state.openQuestionsAnswers).map(([num, answer]) => ({
            [num]: answer,
        }));

        let closedObj = Array.from(this.state.closedQuestionsAnswers).map(([num, answer]) => ({
            [num]: answer,
        }));

        let update = {
            id: this.props.id,
            survey: surveyObj,
            openAnswers: openObj,
            closedAnswers: closedObj,
            vote: true,
        };

        api.post('/api/page/update', update)
            .then((data) => {
                //set cookie
                const cookies = new Cookies();
                const cookieObj = cookies.get('360form');
                let cookieArr;

                if (cookieObj) {
                    cookieArr = Object.values(cookieObj);
                } else {
                    cookieArr = [];
                }

                cookieArr.push(this.props.id);

                const cookieStr = JSON.stringify(cookieArr);

                let date = new Date();
                date.setDate(date.getDate() + 30);

                cookies.set('360form', cookieStr, { path: '/p', expires: date });

                this.setState({
                    surveyAnswers: new Map([]),
                    openQuestionsAnswers: new Map([]),
                    closedQuestionsAnswers: new Map([]),
                    surveySubmitted: true,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };
}

export default Simple;
