import React, { Component } from 'react';
import ArticleSnippet from './ArticleSnippet';
import { Helmet } from 'react-helmet';

class Blog extends Component {
    render() {
        return (
            <main className="contact-container imprint-container blog-container container">
                <Helmet>
                    <title>Blog | feedbee</title>
                </Helmet>
                <div className="contact-form terms-wrapper">
                    <h1 className="contact-txt-big" style={{ alignSelf: 'flex-start' }}>
                        Blog.
                    </h1>
                    <div style={{ alignSelf: 'flex-start' }}>Advice, tips and news.</div>
                    <ArticleSnippet />
                </div>
            </main>
        );
    }
}

export default Blog;
