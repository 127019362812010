import React from 'react';
import { Helmet } from 'react-helmet';
import { HashLink as Link } from 'react-router-hash-link';
import check from '../assets/check-img.png';
import logo from '../assets/logo.png';

const About = (props) => {
    const DOMAIN = `${process.env.DOMAIN}`;
    const cannonical = `${DOMAIN}/about`;
        
    return (
        <main className="contact-container imprint-container container">
            <Helmet>
                <title>About | feedbee</title>
                <link rel="canonical" href={cannonical} />
            </Helmet>

            <div className="contact-form faq-wrapper about">
                <h1
                    className="contact-txt-big"
                    style={{ margin: '-20px 0 20px', alignSelf: 'flex-start' }}
                >
                    Why FEEDBEE?
                </h1>

                <h3 className="about-title" style={{ marginTop: '5px' }}>
                    Feedback is a key to improvement
                </h3>
                <div className="about-txt">
                    Feedback is a tool that can help us to develop, grow, make changes and improve in our
                    professional and personal lives. By gathering feedback, we can learn how our work,
                    performance, behaviour or ideas are perceived by others. Feedback is an essential
                    component of effective work, it improves communication and gives us a clear idea of
                    the things that are done well and the things that require some revision to receive
                    better results, make progress or grow.
                    <br /> <br />
                    Positive peer feedback boosts productivity and proofs that our work and behaviour are
                    appreciated and valued. It promotes positive habits and increases self-awareness
                    related to our actions and behaviours and their influence on the work environment.
                    Negative peer feedback, on the other hand, is a great motivation to improve. It
                    promotes changes, stimulates growth and development, and encourages the search for
                    better solutions.
                </div>
                <h3 className="about-title">
                    Our goal is to provide you with a tool that will allow you to gather feedback and
                    opinions easily and efficiently
                </h3>
                <div className="about-txt">
                    It doesn't matter if you are a business owner, employee, freelancer, self-employed,
                    project manager, future entrepreneur or just in need of collecting feedback for
                    non-work-related reasons - you are definitely in the right place! With FEEDBEE you
                    can prepare a personalized feedback page and use it to conduct a multi-source
                    assessment of your work, behaviour, performance, product, service, business idea or
                    anything else that comes to your mind.
                    <br /> <br />
                    To create a feedback page, you can choose one of our five fully responsive templates.
                    It is totally up to you how your page will look - you can change colours, fonts and
                    shapes, and add images and gifs. It is also your choice what type of questions you
                    will ask - open-ended, close-ended, yes/no or all of them.
                    <br /> <br />
                    Feedback is gathered anonymously, which ensures that your respondents answer
                    questions honestly and the feedback you gain has real value. A graphical presentation
                    of the results will be available instantly in your user panel.
                </div>
                <h3 className="about-title">The 360-degree feedback</h3>
                <div className="about-txt">
                    360-degree evaluation is a process of gathering feedback from subordinates,
                    colleagues and supervisors. When relevant, it might also include feedback from
                    customers or other important stakeholders. This type of multi-source assessment
                    became a popular way of evaluating work performance as it focuses on a variety of
                    points of view.
                    <br /> <br />
                    Using FEEDBEE you can create a 360-degree feedback page and share it with all
                    relevant recipients. A correctly structured survey will bring you a lot of valuable
                    information.
                    <br /> <br /> <br />
                    <b>
                        FEEDBEE is a very versatile tool and it can be used to collect feedback and
                        opinions in many different situations. Whether it is peer feedback or other type
                        of assessment - we have got you covered! It is totally up to you what questions
                        you ask and with whom you share them.
                    </b>
                </div>
                <br />
                <h3 className="about-title">We offer:</h3>
                <div>
                    <ul style={{ marginTop: 0 }}>
                        <li>
                            <img src={check} className="check-img" />
                            <div className="">
                                feedback page creator where you can write your open-ended, close-ended
                                and yes/no questions
                            </div>
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            <div className="">five different themes that you can fully personalize</div>
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            <div className="section pricing-sec">
                                visual presentation of gathered feedback
                            </div>
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            <div className="section pricing-sec">
                                anonymous result page that you can share with whomever you wish
                            </div>
                        </li>
                        <li>
                            <img src={check} className="check-img" />
                            <div className="section pricing-sec">
                                downloadable PDF report for every peer feedback page you create
                            </div>
                        </li>
                    </ul>
                </div>

                {props.user ? null : (
                    <div className="btn-wrapper">
                        <Link to="/auth/sign-up">
                            <button className="start-btn">try for free</button>
                        </Link>
                    </div>
                )}

                <div style={{ marginTop: '20px', fontSize: '14px', alignSelf: 'center' }}>
                    Do you have questions? Check our{' '}
                    <Link to="/faq" className="link-orange">
                        FAQ
                    </Link>{' '}
                    page and{' '}
                    <Link to="/blog" className="link-orange">
                        blog
                    </Link>{' '}
                    section.
                </div>
                <div style={{ marginTop: '5px', fontSize: '14px', alignSelf: 'center' }}>
                    To find out more about our pricing plans go to the{' '}
                    <Link to="/pricing" className="link-orange">
                        pricing
                    </Link>{' '}
                    page.
                </div>
            </div>
        </main>
    );
};

export default About;
