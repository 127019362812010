import React, { useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Message = () => (
    <div className="contact-container checkout container">
        <div className="circle-wrapper">
            <div
                className="success-circle cancel-circle"
                style={{ height: '60px', width: '120px' }}
            ></div>
            <div
                className="success-circle"
                style={{ backgroundColor: '#bccbde', height: '60px', width: '120px' }}
            ></div>
        </div>
        <div className="checkout-txt-big">Your account was successfully deleted.</div>
        <div className="checkout-txt-small">
            If you ever feel like coming back, we will be here for you!
        </div>
    </div>
);

export default function AccountDeleted() {
    useEffect(() => {
        //delay animation
        const timer = setTimeout(() => {
            setIsReady(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return <Message />;
}
