import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import api from '../utils/api';
import { Doughnut } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Document, Page, Text, View, Image, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import 'chartjs-plugin-datalabels';
import quote from '../../assets/quote.png';

class Details extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: '',
            error: '',
            loading: false,
            redirect: false,
        };
    }

    componentDidMount() {
        this._getPage();
    }

    render() {
        if (!this.props.user) return <Redirect to="/auth/sign-in" />;
        if (this.state.page) {
            if (this.props.user && this.props.user._id !== this.state.page.user)
                return <Redirect to="/dashboard" />;
        }
        if (this.state.redirect) return <Redirect to={'/dashboard'} />;

        const page = this.state.page;

        let date;
        let data;
        let total;

        if (page) {
            date = moment(this.state.page.created_at).format('DD.MM.YYYY');

            total = page.answers.yes + page.answers.no;

            data = {
                labels: ['YES', 'NO'],
                datasets: [
                    {
                        data: [page.answers.yes, page.answers.no],
                        backgroundColor: ['#adbdbe', '#7691a3'],
                        hoverBackgroundColor: ['#adbdbe', '#7691a3'],
                    },
                ],
            };
        }

        // //colors for doughnut chart
        // const colors = ['#bfb09b', '#a33a14', '#3c4d5c', '#b06b6b', '#c95e00'];

        //colors for doughnut chart
        const colors = ['#e05915', '#ceac03', '#bfb09b', '#799da8', '#3d4950'];

        // Create PDF Styles
        const styles = StyleSheet.create({
            page: {
                flexDirection: 'column',
                paddingHorizontal: 30,
                paddingVertical: 40,
                paddingRight: 50,
            },
            section: {
                marginBottom: 20,
            },
            question: {
                fontSize: 13,
                fontWeight: 900,
                marginBottom: 5,
                fontFamily: 'Helvetica-Bold',
            },
            squareYes: {
                backgroundColor: '#778ba6',
                height: 10,
                marginTop: 5,
            },
            squareNo: {
                backgroundColor: '#e05915',
                height: 10,
                marginTop: 8,
            },
            surveyQuestion: {
                fontSize: 11,
                marginTop: 1,
            },
            openQuestion: {
                fontSize: 12,
                marginTop: 6,
            },
            quote: {
                width: 15,
                height: 13.5,
            },
            wrapper: {
                flexDirection: 'row',
            },
            squareStronglyDisagree: {
                backgroundColor: '#e05915',
                height: 10,
                marginTop: 5,
            },
            squareDisagree: {
                backgroundColor: '#f3d107',
                height: 10,
                marginTop: 8,
            },
            squareNeutral: {
                backgroundColor: '#bccbde',
                height: 10,
                marginTop: 8,
            },
            squareAgree: {
                backgroundColor: '#778ba6',
                height: 10,
                marginTop: 8,
            },
            squareStronglyAgree: {
                backgroundColor: '#3d4950',
                height: 10,
                marginTop: 8,
            },
        });

        // Create PDF Component
        const MyDocument = () => (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        {page.surveyQuestions.map((el, index) => (
                            <View style={styles.section} key={index}>
                                <Text style={styles.question}>{el.question}</Text>

                                <View
                                    style={[
                                        styles.squareYes,
                                        {
                                            width:
                                                el.yes !== 0
                                                    ? `${this._countPercentage(el.yes, el.no, el.yes)}%`
                                                    : '0.3%',
                                        },
                                    ]}
                                ></View>
                                <Text style={styles.surveyQuestion}>
                                    yes:{' '}
                                    {this._countTotal(el.yes, el.no) === 0
                                        ? 0
                                        : this._countPercentage(el.yes, el.no, el.yes)}
                                    % (votes: {el.yes})
                                </Text>

                                <View
                                    style={[
                                        styles.squareNo,
                                        {
                                            width:
                                                el.no !== 0
                                                    ? `${this._countPercentage(el.yes, el.no, el.no)}%`
                                                    : '0.3%',
                                        },
                                    ]}
                                ></View>
                                <Text style={styles.surveyQuestion}>
                                    no:{' '}
                                    {this._countTotal(el.yes, el.no) === 0
                                        ? 0
                                        : this._countPercentage(el.yes, el.no, el.no)}
                                    % (votes: {el.no})
                                </Text>
                            </View>
                        ))}
                    </View>

                    <View style={styles.section}>
                        {page.closedQuestions.map((el, index) => (
                            <View style={styles.section} key={index}>
                                <Text style={styles.question}>{el.question}</Text>

                                <View
                                    style={[
                                        styles.squareStronglyDisagree,
                                        {
                                            width:
                                                el.stronglyDisagree !== 0
                                                    ? `${this._countClosedPercentage(
                                                          el,
                                                          el.stronglyDisagree
                                                      )}%`
                                                    : '0.3%',
                                        },
                                    ]}
                                ></View>
                                <Text style={styles.surveyQuestion}>
                                    strongly disagree:{' '}
                                    {this._countClosedTotal(el) === 0
                                        ? 0
                                        : this._countClosedPercentage(el, el.stronglyDisagree)}
                                    % (votes: {el.stronglyDisagree})
                                </Text>

                                <View
                                    style={[
                                        styles.squareDisagree,
                                        {
                                            width:
                                                el.disagree !== 0
                                                    ? `${this._countClosedPercentage(el, el.disagree)}%`
                                                    : '0.3%',
                                        },
                                    ]}
                                ></View>
                                <Text style={styles.surveyQuestion}>
                                    disagree:{' '}
                                    {this._countClosedTotal(el) === 0
                                        ? 0
                                        : this._countClosedPercentage(el, el.disagree)}
                                    % (votes: {el.disagree})
                                </Text>

                                <View
                                    style={[
                                        styles.squareNeutral,
                                        {
                                            width:
                                                el.neutral !== 0
                                                    ? `${this._countClosedPercentage(el, el.neutral)}%`
                                                    : '0.3%',
                                        },
                                    ]}
                                ></View>
                                <Text style={styles.surveyQuestion}>
                                    neutral:{' '}
                                    {this._countClosedTotal(el) === 0
                                        ? 0
                                        : this._countClosedPercentage(el, el.neutral)}
                                    % (votes: {el.neutral})
                                </Text>

                                <View
                                    style={[
                                        styles.squareAgree,
                                        {
                                            width:
                                                el.agree !== 0
                                                    ? `${this._countClosedPercentage(el, el.agree)}%`
                                                    : '0.3%',
                                        },
                                    ]}
                                ></View>
                                <Text style={styles.surveyQuestion}>
                                    agree:{' '}
                                    {this._countClosedTotal(el) === 0
                                        ? 0
                                        : this._countClosedPercentage(el, el.agree)}
                                    % (votes: {el.agree})
                                </Text>

                                <View
                                    style={[
                                        styles.squareStronglyAgree,
                                        {
                                            width:
                                                el.stronglyAgree !== 0
                                                    ? `${this._countClosedPercentage(
                                                          el,
                                                          el.stronglyAgree
                                                      )}%`
                                                    : '0.3%',
                                        },
                                    ]}
                                ></View>
                                <Text style={styles.surveyQuestion}>
                                    strongly agree:{' '}
                                    {this._countClosedTotal(el) === 0
                                        ? 0
                                        : this._countClosedPercentage(el, el.stronglyAgree)}
                                    % (votes: {el.stronglyAgree})
                                </Text>
                            </View>
                        ))}
                    </View>

                    <View style={styles.section}>
                        {page.openQuestions.map((el, index) => (
                            <View style={styles.section} key={index}>
                                <Text style={styles.question}>{el.question}</Text>

                                {el.answers.length > 0 ? (
                                    <View>
                                        {el.answers.map((answ, index) => (
                                            <View style={styles.wrapper} key={index}>
                                                <Image src={quote} style={styles.quote} />
                                                <Text style={styles.openQuestion}>{answ}</Text>
                                            </View>
                                        ))}
                                    </View>
                                ) : (
                                    <Text style={styles.openQuestion}>
                                        Nobody has answered this question.
                                    </Text>
                                )}
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        );

        return (
            <main>
                {page ? (
                    <Helmet>
                        <meta name="robots" content="noindex" />
                        <title>{`${page.name} Feedback | feedbee`}</title>
                    </Helmet>
                ) : null}
                {page ? (
                    <div className="details-container container">
                        <div
                            style={{
                                margin: '40px 0 20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div className="section-txt" style={{ marginTop: '0' }}>
                                {!page.published ? <div className="publish">unpublished</div> : null}

                                {page.published ? (
                                    <Link
                                        to={`/p/${this.state.page._id}`}
                                        style={{ textDecoration: 'none', color: '#0b0e11' }}
                                        target={'_blank'}
                                        rel="noopener noreferrer"
                                    >
                                        {page.name}
                                        <FontAwesomeIcon
                                            icon={faExternalLinkAlt}
                                            style={{ fontSize: '20px', margin: '0 0 12px 0' }}
                                        />
                                    </Link>
                                ) : (
                                    <div>{page.name}</div>
                                )}
                                <div className="date">created: {date}</div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '5px',
                                }}
                            >
                                <Link to="/dashboard">
                                    <button
                                        className="download-btn publish-btn"
                                        style={{ backgroundColor: '#799da8' }}
                                    >
                                        dashboard
                                    </button>
                                </Link>

                                {!page.answers ? (
                                    <Link to={`/edit/${this.props.id}`}>
                                        <button
                                            className="download-btn publish-btn"
                                            style={{
                                                backgroundColor: '#ceac03',
                                                marginTop: '5px',
                                            }}
                                        >
                                            edit
                                        </button>
                                    </Link>
                                ) : null}

                                <button
                                    className="download-btn publish-btn"
                                    onClick={this._updatePage}
                                    style={{ marginTop: '5px' }}
                                >
                                    {page.published ? 'unpublish' : 'publish'}
                                </button>

                                <button
                                    className="download-btn publish-btn"
                                    onClick={this._deletePage}
                                    style={{
                                        marginTop: '5px',
                                        backgroundColor: '#e05915',
                                    }}
                                >
                                    delete
                                </button>
                            </div>
                        </div>

                        {page.surveyQuestions && page.surveyQuestions.length > 0 ? (
                            <div className="question survey-title">Yes/No Questions:</div>
                        ) : null}
                        <div className="survey-container">
                            {page.surveyQuestions
                                ? page.surveyQuestions.map((el, index) => (
                                      <div className="survey" key={index}>
                                          <div className="survey-question" style={{ maxWidth: '340px' }}>
                                              {el.question}
                                          </div>
                                          <div className="data-container">
                                              {this._countTotal(el.yes, el.no) > 0 ? (
                                                  <div className="stats-container">
                                                      <div>yes: {el.yes}</div>
                                                      <div>no: {el.no}</div>
                                                      <div style={{ fontWeight: 'bold' }}>
                                                          total: {this._countTotal(el.yes, el.no)}
                                                      </div>
                                                  </div>
                                              ) : (
                                                  'Nobody has answered this question yet.'
                                              )}

                                              {this._countTotal(el.yes, el.no) > 0 ? (
                                                  <Doughnut
                                                      data={{
                                                          labels: ['YES', 'NO'],
                                                          datasets: [
                                                              {
                                                                  data: [el.yes, el.no],
                                                                  backgroundColor: [
                                                                      '#799da8',
                                                                      '#e05915',
                                                                  ],
                                                                  hoverBackgroundColor: [
                                                                      '#799da8',
                                                                      '#e05915',
                                                                  ],
                                                                  borderColor: '#fff9f5',
                                                                  hoverBorderColor: [
                                                                      '#799da8',
                                                                      '#e05915',
                                                                  ],
                                                              },
                                                          ],
                                                      }}
                                                      height={220}
                                                      width={220}
                                                      options={{
                                                          responsive: false,
                                                          tooltips: {
                                                              enabled: false,
                                                          },
                                                          legend: {
                                                              labels: {
                                                                  boxWidth: 12,
                                                              },
                                                          },
                                                          plugins: {
                                                              datalabels: {
                                                                  display: function (context) {
                                                                      return (
                                                                          context.dataset.data[
                                                                              context.dataIndex
                                                                          ] !== 0
                                                                      );
                                                                  },
                                                                  color: '#fcfbfa',
                                                                  fontFamily: "'Work Sans', sans-serif",
                                                                  borderWidth: 1,
                                                                  borderColor: '#fcfbfa',
                                                                  borderRadius: 2,
                                                                  padding: 2,
                                                                  formatter: function (value) {
                                                                      return (
                                                                          (
                                                                              (value * 100) /
                                                                              (el.yes + el.no)
                                                                          ).toFixed(0) + '%'
                                                                      );
                                                                  },
                                                                  font: function (context) {
                                                                      const size = 12;
                                                                      return {
                                                                          size: size,
                                                                          weight: 600,
                                                                      };
                                                                  },
                                                              },
                                                          },
                                                      }}
                                                  />
                                              ) : null}
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>

                        {page.closedQuestions && page.closedQuestions.length > 0 ? (
                            <div className="question survey-title">Close-ended Questions:</div>
                        ) : null}
                        <div className="survey-container">
                            {page.closedQuestions
                                ? page.closedQuestions.map((el, index) => (
                                      <div className="survey" key={index}>
                                          <div className="survey-question" style={{ maxWidth: '340px' }}>
                                              {el.question}
                                          </div>
                                          <div className="data-container">
                                              {this._countClosedTotal(el) > 0 ? (
                                                  <div className="stats-container">
                                                      <div>strongly disagree: {el.stronglyDisagree}</div>
                                                      <div>disagree: {el.disagree}</div>
                                                      <div>neutral: {el.neutral}</div>
                                                      <div>agree: {el.agree}</div>
                                                      <div>strongly agree: {el.stronglyAgree}</div>
                                                      <div style={{ fontWeight: 'bold' }}>
                                                          total: {this._countClosedTotal(el)}
                                                      </div>
                                                  </div>
                                              ) : (
                                                  'Nobody has answered this question yet.'
                                              )}

                                              {this._countClosedTotal(el) > 0 ? (
                                                  <Doughnut
                                                      data={{
                                                          labels: [
                                                              'STRONGLY DISAGREE',
                                                              'DISAGREE',
                                                              'NEUTRAL',
                                                              'AGREE',
                                                              'STRONGLY AGREE',
                                                          ],
                                                          datasets: [
                                                              {
                                                                  data: [
                                                                      el.stronglyDisagree,
                                                                      el.disagree,
                                                                      el.neutral,
                                                                      el.agree,
                                                                      el.stronglyAgree,
                                                                  ],
                                                                  backgroundColor: colors,
                                                                  hoverBackgroundColor: colors,
                                                                  borderColor: '#fff9f5',
                                                                  hoverBorderColor: colors,
                                                              },
                                                          ],
                                                      }}
                                                      height={270}
                                                      width={270}
                                                      options={{
                                                          responsive: false,
                                                          tooltips: {
                                                              enabled: false,
                                                          },
                                                          legend: {
                                                              labels: {
                                                                  boxWidth: 12,
                                                              },
                                                          },
                                                          plugins: {
                                                              datalabels: {
                                                                  display: function (context) {
                                                                      return (
                                                                          context.dataset.data[
                                                                              context.dataIndex
                                                                          ] !== 0
                                                                      );
                                                                  },
                                                                  color: '#fcfbfa',
                                                                  fontFamily: "'Work Sans', sans-serif",
                                                                  borderWidth: 1,
                                                                  borderColor: '#fcfbfa',
                                                                  borderRadius: 2,
                                                                  padding: 2,
                                                                  formatter: function (value) {
                                                                      return (
                                                                          (
                                                                              (value * 100) /
                                                                              (el.stronglyDisagree +
                                                                                  el.disagree +
                                                                                  el.neutral +
                                                                                  el.agree +
                                                                                  el.stronglyAgree)
                                                                          ).toFixed(0) + '%'
                                                                      );
                                                                  },
                                                                  font: function (context) {
                                                                      const size = 12;
                                                                      return {
                                                                          size: size,
                                                                          weight: 600,
                                                                      };
                                                                  },
                                                              },
                                                          },
                                                      }}
                                                  />
                                              ) : null}
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>

                        {page.openQuestions && page.openQuestions.length > 0 ? (
                            <div>
                                <div className="question survey-title">Open-ended Questions:</div>
                            </div>
                        ) : null}
                        <div className="survey-container answers">
                            {page.openQuestions
                                ? page.openQuestions.map((el, index) => (
                                      <div className="survey survey-open" key={index}>
                                          <div className="survey-question">{el.question}</div>
                                          {el.answers.length > 0 ? (
                                              <div>
                                                  {el.answers.map((answ, index) => (
                                                      <div
                                                          style={{ display: 'flex', margin: '20px 0 0' }}
                                                          key={index}
                                                      >
                                                          <div className="icon-wrapper">
                                                              <FontAwesomeIcon
                                                                  icon={faQuoteRight}
                                                                  className="icon"
                                                              />
                                                          </div>
                                                          <div className="answ">{answ}</div>
                                                      </div>
                                                  ))}
                                              </div>
                                          ) : (
                                              'Nobody has answered this question yet.'
                                          )}
                                      </div>
                                  ))
                                : null}
                        </div>

                        {page.answers === 0 ? null : (
                            <div className="btns-details">
                                {!this.state.loading && (
                                    <PDFDownloadLink
                                        document={<MyDocument />}
                                        fileName={`${page.name}`}
                                        className={
                                            this.props.user.isSubscribed
                                                ? 'download-btn pdf-btn'
                                                : 'download-btn pdf-btn disabled-btn'
                                        }
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading
                                                ? 'loading document'
                                                : this.props.user.isSubscribed
                                                ? 'download your feedback as pdf'
                                                : 'upgrade to download your feedback as pdf'
                                        }
                                    </PDFDownloadLink>
                                )}
                                <Link
                                    to={`/f/${page.slug}`}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <button className="download-btn pdf-btn feedback-btn">
                                        share your feedback with the world
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>
                ) : null}
            </main>
        );
    }

    _getPage = () => {
        api.get(`/api/page/getOne/${this.props.id}`)
            .then((data) => {
                this.setState({
                    page: data.page,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _updatePage = () => {
        this.setState({
            loading: true,
        });

        let update = { pageId: this.state.page._id, published: this.state.page.published };

        api.post('/api/page/update', update)
            .then((data) => {
                this.setState({
                    page: data.updatedPage,
                    loading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _deletePage = () => {
        api.delete(`/api/page/delete/${this.state.page._id}`)
            .then((data) => {
                this.setState({
                    redirect: true,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _countTotal(a, b) {
        return a + b;
    }

    _countClosedTotal(el) {
        return el.stronglyDisagree + el.disagree + el.neutral + el.agree + el.stronglyAgree;
    }

    _countPercentage(a, b, num) {
        return Math.round((num / (a + b)) * 100);
    }

    _countClosedPercentage(el, num) {
        return Math.round(
            (num / (el.stronglyDisagree + el.disagree + el.neutral + el.agree + el.stronglyAgree)) * 100
        );
    }
}

export default Details;
