import React from 'react';
import { Helmet } from 'react-helmet';
import terms from '../terms.html';

const Terms = () => {
    return (
        <main className="contact-container imprint-container container">
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>Terms and Conditions | feedbee</title>
            </Helmet>

            <div className="contact-form terms-wrapper">
                <div className="contact-txt-big" style={{ alignSelf: 'flex-start' }}>
                    Terms and conditions.
                </div>
                <div dangerouslySetInnerHTML={{ __html: terms }}></div>
            </div>
        </main>
    );
};

export default Terms;
