import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Templates from '../assets/templates/templates.json';
import SimplePreview from './Templates/SimplePreview';
import BlackWhitePreview from './Templates/BlackWhitePreview';
import ImagePreview from './Templates/ImagePreview';
import ThreeColorsPreview from './Templates/ThreeColorsPreview';
import GeometricPreview from './Templates/GeometricPreview';
import { CompactPicker } from 'react-color';
import { Helmet } from 'react-helmet';

import api from './utils/api';

class Edit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            isLoaded: false,
            error: '',
        };
    }

    componentDidMount() {
        this._getPage();
    }

    render() {
        if (!this.props.user) return <Redirect to="/auth/sign-in" />; // this is actually the protection
        //if (!this.props.user.isSubscribed) return <Redirect to="/account" />;
        if (this.state.user) {
            if (this.props.user && this.props.user._id !== this.state.user)
                return <Redirect to="/dashboard" />;
        }
        if (this.state.answers) return <Redirect to={`/dashboard/${this.props.id}`} />;

        const page = this.state;

        const props = {
            descriptionColor: this.state.descriptionColor,
            textColor: this.state.textColor,
            buttonColor: this.state.buttonColor,
            description: this.state.description,
            graphics: this.state.graphics,
            background: this.state.backgroundColor,
            backgroundTwo: this.state.backgroundColorTwo,
            descriptionFont: this.state.descriptionFont,
            questionFont: this.state.questionFont,
            radius: this.state.buttonRadius,
            fontWeight: this.state.fontWeight,
            userType: this.state.userType,
            surveyQuestions: this.state.surveyQuestions,
            openQuestions: this.state.openQuestions,
            closedQuestions: this.state.closedQuestions,
            hidePlaceholder: this.state.hidePlaceholder,
            isQuestionValid: this._isQuestionValid,
        };

        const colors = [
            '#4D4D4D',
            '#999999',
            '#FFFFFF',
            '#E97451',
            '#FDA934',
            '#FFEE7A',
            '#DBDF00',
            '#ACCF57',
            '#9BD1CC',
            '#C9E8f0',
            '#E2A4C6',
            '#F4C2C2',
            '#333333',
            '#808080',
            '#CCCCCC',
            '#D33115',
            '#E27300',
            '#FFD130',
            '#B0BC00',
            '#5B8D33',
            '#6A9FA3',
            '#8EC3E6',
            '#B668A1',
            '#EE959E',
            '#000000',
            '#666666',
            '#B3B3B3',
            '#9F0500',
            '#C45100',
            '#FFB436',
            '#676E01',
            '#194D33',
            '#3C6979',
            '#3A5D9C',
            '#743282',
            '#CD5E77',
        ];

        return (
            <main className="create-container container">
                <Helmet>
                    <title>Edit | feedbee</title>
                </Helmet>

                {this.state.isLoaded ? (
                    <div className="create-wrapper">
                        <div className="form-container">
                            <div className="section-txt" style={{ marginBottom: '45px' }}>
                                Describe
                            </div>
                            <input
                                type="text"
                                value={page.name}
                                onChange={(evt) => this._handleInputChange('name', evt.target.value)}
                                className="form-input"
                                placeholder="eg. Feedback Q1 2023."
                                name="name"
                                autoComplete="off"
                                required
                            />
                            <label htmlFor="name" className="input-label">
                                <span className="input-txt">
                                    Pick a name:
                                    <div className="create-form-info">
                                        It won't be used anywhere on your page.
                                    </div>
                                </span>
                            </label>
                            <br />
                            <br />
                            <br />
                            <br />
                            <textarea
                                value={page.description}
                                placeholder="Ask for feedback. Explain why you want it. &#10;Take as much space as you need."
                                onChange={(evt) =>
                                    this._handleInputChange('description', evt.target.value)
                                }
                                name="msg"
                                className="msg-input"
                                autoComplete="off"
                                rows={2}
                            />
                            <label htmlFor="msg" className="msg-label">
                                <span className="msg-txt" style={{ marginTop: '0px' }}>
                                    Write a short introduction:
                                    <div className="create-form-info">
                                        If you don't want, just skip it.
                                    </div>
                                </span>
                            </label>

                            <div className="section-txt" style={{ marginBottom: '-10px' }}>
                                Ask
                            </div>

                            <div>
                                <div
                                    className="subsection-title"
                                    style={{ marginTop: '30px', display: 'flex' }}
                                >
                                    <div>Yes/No Questions</div>
                                </div>
                            </div>

                            <div>
                                {this.state.surveyLength.map((num, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            value={this.state.surveyQuestions.get(num) || ''}
                                            onChange={(e) =>
                                                this._handleSurveyInput(num, e.target.value)
                                            }
                                            className="form-input"
                                            placeholder="Your yes/no question."
                                            name="survey"
                                            autoComplete="off"
                                        />
                                        <label htmlFor="survey" className="input-label"></label>
                                    </div>
                                ))}

                                <button
                                    onClick={() => this._appendInput()}
                                    className="radius-btn"
                                    style={{ marginTop: '10px' }}
                                >
                                    add+
                                </button>

                                {this.state.surveyLength.length >= 1 ? (
                                    <button
                                        onClick={() => this._removeInput()}
                                        className="radius-btn"
                                        style={{ marginTop: '10px' }}
                                    >
                                        remove-
                                    </button>
                                ) : null}
                            </div>

                            <div>
                                <div
                                    className="subsection-title"
                                    style={{ marginTop: '30px', display: 'flex' }}
                                >
                                    <div>Close-ended Questions</div>
                                </div>
                            </div>

                            <div>
                                {this.state.closedQuestionsLength.map((num, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            value={this.state.closedQuestions.get(num) || ''}
                                            onChange={(e) =>
                                                this._handleClosedQuestionInput(num, e.target.value)
                                            }
                                            className="form-input"
                                            placeholder="Your close-ended question."
                                            name="survey"
                                            autoComplete="off"
                                        />
                                        <label htmlFor="survey" className="input-label"></label>
                                    </div>
                                ))}

                                <button
                                    onClick={() => this._appendClosedInput()}
                                    className="radius-btn"
                                    style={{ marginTop: '10px' }}
                                >
                                    add+
                                </button>

                                {this.state.closedQuestionsLength.length >= 1 ? (
                                    <button
                                        onClick={() => this._removeClosedInput()}
                                        className="radius-btn"
                                        style={{ marginTop: '10px' }}
                                    >
                                        remove-
                                    </button>
                                ) : null}
                            </div>

                            <div>
                                <div
                                    className="subsection-title"
                                    style={{ marginTop: '30px', display: 'flex' }}
                                >
                                    <div>Open-ended Questions</div>
                                </div>
                            </div>

                            <div>
                                {this.state.openQuestionsLength.map((num, index) => (
                                    <div key={index}>
                                        <input
                                            type="text"
                                            value={this.state.openQuestions.get(num) || ''}
                                            onChange={(e) =>
                                                this._handleOpenQuestionInput(num, e.target.value)
                                            }
                                            className="form-input"
                                            placeholder="Your open-ended question."
                                            name="survey"
                                            autoComplete="off"
                                        />
                                        <label htmlFor="survey" className="input-label"></label>
                                    </div>
                                ))}

                                <button
                                    onClick={() => this._appendOpenInput()}
                                    className="radius-btn"
                                    style={{ marginTop: '10px' }}
                                >
                                    add+
                                </button>

                                {this.state.openQuestionsLength.length >= 1 ? (
                                    <button
                                        onClick={() => this._removeOpenInput()}
                                        className="radius-btn"
                                        style={{ marginTop: '10px' }}
                                    >
                                        remove-
                                    </button>
                                ) : null}
                            </div>

                            <div className="section-txt">Personalize</div>
                            <div className="subsection-title" style={{ marginTop: '15px' }}>
                                Pick the template:
                            </div>
                            <div className="templates-container">
                                {Templates.templatesInfo.map((el, index) => (
                                    <div key={index}>
                                        <button
                                            className="template-btn"
                                            onClick={() => this._handleTemplatePick(el.name)}
                                            style={{
                                                borderColor:
                                                    page.template === el.name ? '#799da8' : null,
                                                backgroundImage: `url(${el.backgroundImg})`,
                                            }}
                                        />
                                        <div
                                            onClick={() => this._handleTemplatePick(el.name)}
                                            className="template-name"
                                            style={{
                                                color: page.template === el.name ? '#799da8' : null,
                                                fontWeight: page.template === el.name ? 'bold' : null,
                                            }}
                                        >
                                            {el.name}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {page.template === 'image' || page.template === 'b&w' ? (
                                <div>
                                    <br />
                                    <br />
                                </div>
                            ) : null}
                            {page.template === 'image' || page.template === 'b&w' ? (
                                <input
                                    type="url"
                                    value={page.graphics ? page.graphics : ''}
                                    onChange={(evt) =>
                                        this._handleInputChange('graphics', evt.target.value)
                                    }
                                    className="form-input"
                                    placeholder="link to your graphics"
                                    autoComplete="off"
                                    name="graphics"
                                />
                            ) : null}
                            {page.template === 'image' || page.template === 'b&w' ? (
                                <label htmlFor="graphics" className="input-label">
                                    <span
                                        className="input-txt"
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        Do you want to add your own{' '}
                                        {page.template === 'image' ? 'background' : null} graphics?
                                        <span className="create-form-info">
                                            It has to be a direct link to your graphics (gifs are welcome
                                            too!).
                                        </span>
                                    </span>
                                </label>
                            ) : null}
                            {page.template === 'image' || page.template === 'b&w' ? (
                                <div>
                                    <br />
                                </div>
                            ) : null}

                            <div className="subsection-title" style={{ marginTop: '15px' }}>
                                Pick font for your introduction:
                            </div>
                            <div className="templates-container">
                                {Templates.fonts.map((el, index) => (
                                    <button
                                        className="font-btn"
                                        onClick={() => this._handlePick('descriptionFont', el)}
                                        style={{
                                            fontFamily: el,
                                            borderColor: page.descriptionFont === el ? '#799da8' : null,
                                        }}
                                        key={index}
                                    >
                                        abc
                                    </button>
                                ))}
                            </div>

                            <div className="subsection-title">Pick font weight:</div>
                            <div style={{ display: 'flex' }}>
                                {Templates.fontWeight.map((el, index) => (
                                    <button
                                        className="radius-btn"
                                        onClick={() => this._handlePick('fontWeight', el)}
                                        style={{
                                            borderColor: page.fontWeight === el ? '#799da8' : null,
                                        }}
                                        key={index}
                                    >
                                        {el}
                                    </button>
                                ))}
                            </div>

                            <div className="subsection-title">Pick font for your questions:</div>
                            <div className="templates-container">
                                {Templates.fontsQuestions.map((el, index) => (
                                    <button
                                        className="font-btn"
                                        onClick={() => this._handlePick('questionFont', el)}
                                        style={{
                                            fontFamily: el,
                                            borderColor: page.questionFont === el ? '#799da8' : null,
                                        }}
                                        key={index}
                                    >
                                        abc
                                    </button>
                                ))}
                            </div>

                            <div className="subsection-title">Play with colors:</div>
                            <div className="create-form-info">
                                Pick from available swatches or add your own HEX/RGB.
                            </div>
                            {page.template === 'image' ? null : (
                                <div>
                                    <span className="form-label">
                                        Background{page.template === 'colors' ? ' One' : null}:
                                    </span>
                                    <CompactPicker
                                        color={page.backgroundColor}
                                        colors={colors}
                                        onChangeComplete={this._handleBackgroundColorChange}
                                    />
                                </div>
                            )}
                            {page.template === 'colors' || page.template === 'zigzag' ? (
                                <div>
                                    <span className="form-label">
                                        {page.template === 'colors' ? 'Background Two:' : 'Footer:'}
                                    </span>
                                    <CompactPicker
                                        color={page.backgroundColorTwo}
                                        colors={colors}
                                        onChangeComplete={this._handleBackgroundColorTwoChange}
                                    />
                                </div>
                            ) : null}
                            <span className="form-label">Introduction: </span>
                            <div style={{ backgroundColor: '#fff9f5' }}>
                                <CompactPicker
                                    color={page.descriptionColor}
                                    colors={colors}
                                    onChangeComplete={this._handleDescriptionColorChange}
                                />
                            </div>
                            <span className="form-label">Questions: </span>
                            <div style={{ backgroundColor: '#fff9f5' }}>
                                <CompactPicker
                                    color={page.textColor}
                                    colors={colors}
                                    onChangeComplete={this._handleTextColorChange}
                                />
                            </div>
                            <span className="form-label">Button:</span>
                            <div style={{ backgroundColor: '#fff9f5' }}>
                                <CompactPicker
                                    color={page.buttonColor}
                                    colors={colors}
                                    onChangeComplete={this._handleButtonColorChange}
                                />
                            </div>
                            <div className="subsection-title">Pick button style:</div>
                            <div style={{ display: 'flex' }}>
                                {Templates.buttonRadius.map((el, index) => (
                                    <button
                                        className="radius-btn"
                                        onClick={() => this._handlePick('buttonRadius', el)}
                                        style={{
                                            borderColor: page.buttonRadius === el ? '#799da8' : null,
                                            borderRadius: el,
                                        }}
                                        key={index}
                                    >
                                        BUTTON
                                    </button>
                                ))}
                            </div>
                            <br />
                            <br />
                            <button className="create-btn" onClick={this._handleFormSubmit}>
                                update
                            </button>
                            <br />
                            <br />
                            {this.state.error}
                        </div>

                        <div className="preview-container">
                            <div className="section-txt">Preview</div>

                            {this.state.template === 'simple' ? <SimplePreview {...props} /> : null}

                            {this.state.template === 'b&w' ? <BlackWhitePreview {...props} /> : null}

                            {this.state.template === 'image' ? <ImagePreview {...props} /> : null}

                            {this.state.template === 'colors' ? <ThreeColorsPreview {...props} /> : null}

                            {this.state.template === 'zigzag' ? <GeometricPreview {...props} /> : null}
                        </div>

                        <div className="preview-display-info">
                            <div className="section-txt">Preview</div>
                            <p>
                                Try to rotate your device or switch to a bigger screen to see the
                                preview.
                            </p>
                        </div>
                    </div>
                ) : null}
                {this.state.redirect ? <Redirect to={'/dashboard'} /> : null}
            </main>
        );
    }

    _getPage = () => {
        api.get(`/api/page/getOne/${this.props.id}`)
            .then((data) => {
                const page = data.page;

                let surveyQuestions = new Map([]);
                let openQuestions = new Map([]);
                let closedQuestions = new Map([]);
                let surveyLength = [];
                let openQuestionsLength = [];
                let closedQuestionsLength = [];

                if (page.surveyQuestions) {
                    page.surveyQuestions.map((el) => {
                        let count = surveyLength.length + 1;
                        surveyLength.push(count);
                        surveyQuestions.set(el.num, el.question);
                    });
                }

                if (page.openQuestions) {
                    page.openQuestions.map((el) => {
                        let count = openQuestionsLength.length + 1;
                        openQuestionsLength.push(count);
                        openQuestions.set(el.num, el.question);
                    });
                }

                if (page.closedQuestions) {
                    page.closedQuestions.map((el) => {
                        let count = closedQuestionsLength.length + 1;
                        closedQuestionsLength.push(count);
                        closedQuestions.set(el.num, el.question);
                    });
                }

                this.setState({
                    template: page.template,
                    name: page.name,
                    description: page.description,
                    descriptionColor: page.descriptionColor,
                    backgroundColor: page.background,
                    backgroundColorTwo: page.backgroundTwo,
                    textColor: page.textColor,
                    buttonColor: page.buttonColor,
                    buttonRadius: page.radius,
                    fontWeight: page.fontWeight,
                    descriptionFont: page.descriptionFont,
                    questionFont: page.questionFont,
                    graphics: page.graphics,
                    answers: page.answers,
                    surveyQuestions,
                    surveyLength,
                    openQuestions,
                    openQuestionsLength,
                    closedQuestions,
                    closedQuestionsLength,
                    hidePlaceholder: true,
                    isLoaded: true,
                    user: page.user,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _hidePlaceholder = () => {
        this.setState({
            hidePlaceholder: true,
        });
    };

    _isQuestionValid = (question) => {
        return question.match(/^\s*$/) === null;
    };

    _appendInput = () => {
        const newSurveyQuestion = this.state.surveyLength.length + 1;
        this.setState((prevState) => ({
            surveyLength: prevState.surveyLength.concat([newSurveyQuestion]),
        }));
    };

    _removeInput = () => {
        let inputs = this.state.surveyLength;
        let questions = this.state.surveyQuestions;

        if (inputs.length > questions.size) {
            inputs.pop();
        } else {
            inputs.pop();
            questions.delete(this.state.surveyQuestions.size);
        }

        this.setState({
            surveyLength: inputs,
            surveyQuestions: questions,
        });
    };

    _appendOpenInput = () => {
        const newOpenQuestion = this.state.openQuestionsLength.length + 1;
        this.setState((prevState) => ({
            openQuestionsLength: prevState.openQuestionsLength.concat([newOpenQuestion]),
        }));
    };

    _removeOpenInput = () => {
        let inputs = this.state.openQuestionsLength;
        let questions = this.state.openQuestions;

        if (inputs.length > questions.size) {
            inputs.pop();
        } else {
            inputs.pop();
            questions.delete(this.state.openQuestions.size);
        }

        this.setState({
            openQuestionsLength: inputs,
            openQuestions: questions,
        });
    };

    _appendClosedInput = () => {
        const newClosedQuestion = this.state.closedQuestionsLength.length + 1;
        this.setState((prevState) => ({
            closedQuestionsLength: prevState.closedQuestionsLength.concat([newClosedQuestion]),
        }));
    };

    _removeClosedInput = () => {
        let inputs = this.state.closedQuestionsLength;
        let questions = this.state.closedQuestions;

        if (inputs.length > questions.size) {
            inputs.pop();
        } else {
            inputs.pop();
            questions.delete(this.state.closedQuestions.size);
        }

        this.setState({
            closedQuestionsLength: inputs,
            closedQuestions: questions,
        });
    };

    _handleInputChange = (key, newValue) => {
        this.setState({
            [key]: newValue,
        });
    };

    _handleSurveyInput = (key, newValue) => {
        let surveyMap = this.state.surveyQuestions;
        surveyMap.set(key, newValue);

        this.setState({
            surveyQuestions: surveyMap,
        });
    };

    _handleOpenQuestionInput = (key, newValue) => {
        let openQuestionsMap = this.state.openQuestions;
        openQuestionsMap.set(key, newValue);

        this.setState({
            openQuestions: openQuestionsMap,
        });
    };

    _handleClosedQuestionInput = (key, newValue) => {
        let closedQuestionsMap = this.state.closedQuestions;
        closedQuestionsMap.set(key, newValue);

        this.setState({
            closedQuestions: closedQuestionsMap,
        });
    };

    _handleFormSubmit = () => {
        this.setState({
            error: '',
        });

        let page = {};

        page.id = this.props.id;
        page.template = this.state.template;
        page.name = this.state.name;
        page.description = this.state.description;
        page.descriptionColor = this.state.descriptionColor;
        page.textColor = this.state.textColor;
        page.buttonColor = this.state.buttonColor;
        page.radius = this.state.buttonRadius;
        page.background = this.state.backgroundColor;
        if (this.state.backgroundColorTwo) page.backgroundTwo = this.state.backgroundColorTwo;
        page.descriptionFont = this.state.descriptionFont;
        page.questionFont = this.state.questionFont;
        page.fontWeight = this.state.fontWeight;
        if (this.state.graphics) page.graphics = this.state.graphics;
        if (this.state.surveyQuestions.size > 0) {
            let obj = Array.from(this.state.surveyQuestions).flatMap(([num, question]) => {
                if (this._isQuestionValid(question)) {
                    return {
                        num,
                        question,
                        yes: 0,
                        no: 0,
                    };
                } else {
                    return [];
                }
            });

            if (obj.length > 0) {
                page.surveyQuestions = obj;
            }
        }
        if (this.state.openQuestions.size > 0) {
            let obj = Array.from(this.state.openQuestions).flatMap(([num, question]) => {
                if (this._isQuestionValid(question)) {
                    return {
                        num,
                        question,
                        answers: [],
                    };
                } else {
                    return [];
                }
            });

            if (obj.length > 0) {
                page.openQuestions = obj;
            }
        }
        if (this.state.closedQuestions.size > 0) {
            let obj = Array.from(this.state.closedQuestions).flatMap(([num, question]) => {
                if (this._isQuestionValid(question)) {
                    return {
                        num,
                        question,
                        stronglyDisagree: 0,
                        disagree: 0,
                        neutral: 0,
                        agree: 0,
                        stronglyAgree: 0,
                    };
                } else {
                    return [];
                }
            });

            if (obj.length > 0) {
                page.closedQuestions = obj;
            }
        }

        api.post('/api/page/edit', page)
            .then((data) => {
                const url = `/p/${data.updatedPage._id}`;
                const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
                if (newWindow) newWindow.opener = null;

                this.setState({
                    template: 'simple',
                    name: '',
                    description: '',
                    graphics: '',
                    redirect: true,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _handleTemplatePick = (template) => {
        this.setState({
            template,
            buttonRadius: Templates[template].buttonRadius,
            backgroundColor: Templates[template].backgroundColor,
            backgroundColorTwo: Templates[template].backgroundColorTwo,
            textColor: Templates[template].textColor,
            buttonColor: Templates[template].buttonColor,
            descriptionColor: Templates[template].descriptionColor,
            descriptionFont: Templates[template].descriptionFont,
            questionFont: Templates[template].questionFont,
            fontWeight: Templates[template].fontWeight,
        });
    };

    _handleBackgroundColorChange = (color) => {
        this.setState({ backgroundColor: color.hex });
    };

    _handleBackgroundColorTwoChange = (color) => {
        this.setState({ backgroundColorTwo: color.hex });
    };

    _handleTextColorChange = (color) => {
        this.setState({ textColor: color.hex });
    };

    _handleDescriptionColorChange = (color) => {
        this.setState({ descriptionColor: color.hex });
    };

    _handleButtonColorChange = (color) => {
        this.setState({ buttonColor: color.hex });
    };

    _handlePick = (key, newValue) => {
        this.setState({
            [key]: newValue,
        });
    };
}

export default Edit;
