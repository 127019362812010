import React from 'react';
import { Helmet } from 'react-helmet';
import { HashLink as Link } from 'react-router-hash-link';
import check from '../assets/check-img.png';

const Privacy = () => {
    return (
        <main className="contact-container imprint-container container">
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>Privacy Policy | feedbee</title>
            </Helmet>

            <div className="contact-form faq-wrapper">
                <div
                    className="contact-txt-big"
                    style={{ marginBottom: '20px', alignSelf: 'flex-start' }}
                >
                    Privacy policy.
                </div>
                <b style={{ alignSelf: 'flex-start' }}>Last updated: February 1, 2023.</b>
                <br />
                <br />
                <div>
                    We take the protection of your privacy very seriously. feedbee.co is not and will
                    never be financed by your personal information. The data that we collect is necessary
                    to provide you with our service.
                </div>
                <ul>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>COOKIES</div>
                            <div className="faq-answ">
                                We use only technical cookies that are essential for the correct
                                functioning of our website. They are not used for tracking or monitoring
                                your behaviour. <br />
                                To respect the privacy of our users, feedbee.co tracks statistics
                                anonymously using an open-source analytics tool -{' '}
                                <a href="https://plausible.io/" target="_blank" className="privacy-link">
                                    Plausible
                                </a>
                                . Plausible does not set any tracking cookies on your device and does not
                                collect any personal data or personally identifiable information.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>USER ACCOUNT</div>
                            <div className="faq-answ">
                                To use the service offered by feedbee.co, you have to create a user
                                account. Registration requires providing first name, last name and
                                e-mail. To complete the registration user password has to be set.
                                <br /> <br />
                                All data provided during user registration is necessary for the correct
                                functioning of the service.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>PAYMENTS</div>
                            <div className="faq-answ">
                                To subscribe to our service, you have to provide payment information. To
                                guarantee the highest level of security, we handle all our payments
                                entirely through word’s leading online payment processing platform -{' '}
                                <a
                                    href="https://stripe.com/en-de"
                                    target="_blank"
                                    className="privacy-link"
                                >
                                    Stripe
                                </a>
                                .
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>DELETION OF DATA</div>
                            <div className="faq-answ">
                                Each feedbee.co subscriber can delete the account in the{' '}
                                <Link to="/account" className="privacy-link-color">
                                    account section
                                </Link>{' '}
                                . Account deletion will delete all the data, including first name, last
                                name, e-mail, and all the data related to the activity within the
                                service.
                                <br /> <br />
                                <b>Deleted data cannot be recovered. </b>
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>CONTACT FORM</div>
                            <div className="faq-answ">
                                You can contact us by using the{' '}
                                <Link to="/contact" className="privacy-link-color">
                                    contact form
                                </Link>{' '}
                                available on the website. To send an inquiry a valid e-mail address is
                                required. By sending an inquiry you agree for your personal data to be
                                processed. Any personal data collected during contact via the contact
                                form, will be deleted in accordance with statutory requirements after the
                                inquiry has been resolved.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>SECURITY</div>
                            <div className="faq-answ">
                                feedbee.co uses a modern encryption level in connection with SSL (Secure
                                Sockets Layer), which is the protocol for establishing authenticated and
                                encrypted links between networked computers. The closed key or lock in
                                the browser’s status bar indicates that the website is protected with the
                                SSL certificate.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>TRANSPARENCY</div>
                            <div className="faq-answ">
                                We are fully transparent about the usage of your personal data. If you
                                have questions or comments about our privacy policy, contact us using our{' '}
                                <Link to="/contact" className="privacy-link-color">
                                    contact form
                                </Link>
                                .
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>AMENDMENTS TO THIS PRIVACY POLICY</div>
                            <div className="faq-answ">
                                The preceding Privacy Policy is currently valid.
                                <br />
                                <br />
                                This Privacy Statement may need to be changed due to the development of
                                our service or due to changes in the regulatory or statutory
                                requirements.
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </main>
    );
};

export default Privacy;
