import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import api from '../utils/api';
import { Doughnut } from 'react-chartjs-2';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import NotFound from '../NotFound';
import logo from '../../assets/logo.png';
import 'chartjs-plugin-datalabels';

class Feedback extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: '',
            error: '',
        };
    }

    async componentDidMount() {
        let feedback = await this._getPage();
    }

    render() {
        const page = this.state.page;

        let data;
        let total;

        if (page) {
            total = page.answers.yes + page.answers.no;

            data = {
                labels: ['YES', 'NO'],
                datasets: [
                    {
                        data: [page.answers.yes, page.answers.no],
                        backgroundColor: ['#adbdbe', '#7691a3'],
                        hoverBackgroundColor: ['#adbdbe', '#7691a3'],
                    },
                ],
            };
        }

        return (
            <main>
                {page && page.published ? (
                    <div className="feedback-container">
                        <Helmet>
                            <meta name="robots" content="noindex" />
                            <title>Feedback | feedbee</title>
                        </Helmet>
                        <NavLink className="" to="/#start" smooth>
                            <img src={logo} className="logo" alt="logo" />
                        </NavLink>
                        <div className="details-container">
                            {page.surveyQuestions.length > 0 ? (
                                <div className="question survey-title" style={{ marginTop: '30px' }}>
                                    Yes/No Questions:
                                </div>
                            ) : null}
                            <div className="survey-container">
                                {page.surveyQuestions.map((el, index) => (
                                    <div className="survey" key={index}>
                                        <div className="survey-question" style={{ maxWidth: '340px' }}>
                                            {el.question}
                                        </div>
                                        <div className="data-container">
                                            {this._countTotal(el.yes, el.no) > 0
                                                ? null
                                                : 'Nobody has answered this question yet.'}

                                            {this._countTotal(el.yes, el.no) > 0 ? (
                                                <Doughnut
                                                    data={{
                                                        labels: ['YES', 'NO'],
                                                        datasets: [
                                                            {
                                                                data: [el.yes, el.no],
                                                                backgroundColor: ['#799da8', '#e05915'],
                                                                hoverBackgroundColor: [
                                                                    '#799da8',
                                                                    '#e05915',
                                                                ],
                                                                borderColor: '#fff9f5',
                                                                hoverBorderColor: ['#799da8', '#e05915'],
                                                            },
                                                        ],
                                                    }}
                                                    height={220}
                                                    width={220}
                                                    options={{
                                                        responsive: false,
                                                        tooltips: {
                                                            enabled: false,
                                                        },
                                                        plugins: {
                                                            datalabels: {
                                                                display: function (context) {
                                                                    return (
                                                                        context.dataset.data[
                                                                            context.dataIndex
                                                                        ] !== 0
                                                                    );
                                                                },
                                                                color: '#fcfbfa',
                                                                fontFamily: "'Work Sans', sans-serif",
                                                                borderWidth: 1,
                                                                borderColor: '#fcfbfa',
                                                                borderRadius: 2,
                                                                padding: 2,
                                                                formatter: function (value) {
                                                                    return (
                                                                        (
                                                                            (value * 100) /
                                                                            (el.yes + el.no)
                                                                        ).toFixed(0) + '%'
                                                                    );
                                                                },
                                                                font: function (context) {
                                                                    const size = 12;
                                                                    return {
                                                                        size: size,
                                                                        weight: 600,
                                                                    };
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {page.closedQuestions.length > 0 ? (
                                <div className="question survey-title">Close-ended Questions:</div>
                            ) : null}
                            <div className="survey-container">
                                {page.closedQuestions.map((el, index) => (
                                    <div className="survey" key={index}>
                                        <div className="survey-question" style={{ maxWidth: '340px' }}>
                                            {el.question}
                                        </div>
                                        <div className="data-container">
                                            {this._countClosedTotal(
                                                el.stronglyDisagree,
                                                el.disagree,
                                                el.neutral,
                                                el.agree,
                                                el.stronglyAgree
                                            ) > 0
                                                ? null
                                                : 'Nobody has answered this question yet.'}

                                            {this._countClosedTotal(
                                                el.stronglyDisagree,
                                                el.disagree,
                                                el.neutral,
                                                el.agree,
                                                el.stronglyAgree
                                            ) > 0 ? (
                                                <Doughnut
                                                    data={{
                                                        labels: [
                                                            'STRONGLY DISAGREE',
                                                            'DISAGREE',
                                                            'NEUTRAL',
                                                            'AGREE',
                                                            'STRONGLY AGREE',
                                                        ],
                                                        datasets: [
                                                            {
                                                                data: [
                                                                    el.stronglyDisagree,
                                                                    el.disagree,
                                                                    el.neutral,
                                                                    el.agree,
                                                                    el.stronglyAgree,
                                                                ],
                                                                backgroundColor: [
                                                                    '#e05915',
                                                                    '#ceac03',
                                                                    '#bfb09b',
                                                                    '#799da8',
                                                                    '#3d4950',
                                                                ],
                                                                hoverBackgroundColor: [
                                                                    '#e05915',
                                                                    '#ceac03',
                                                                    '#bfb09b',
                                                                    '#799da8',
                                                                    '#3d4950',
                                                                ],
                                                                borderColor: '#fff9f5',
                                                                hoverBorderColor: [
                                                                    '#e05915',
                                                                    '#ceac03',
                                                                    '#bfb09b',
                                                                    '#799da8',
                                                                    '#3d4950',
                                                                ],
                                                            },
                                                        ],
                                                    }}
                                                    height={270}
                                                    width={270}
                                                    options={{
                                                        responsive: false,
                                                        tooltips: {
                                                            enabled: false,
                                                        },
                                                        legend: {
                                                            labels: {
                                                                boxWidth: 12,
                                                            },
                                                        },
                                                        plugins: {
                                                            datalabels: {
                                                                display: function (context) {
                                                                    return (
                                                                        context.dataset.data[
                                                                            context.dataIndex
                                                                        ] !== 0
                                                                    );
                                                                },
                                                                color: '#fcfbfa',
                                                                fontFamily: "'Work Sans', sans-serif",
                                                                borderWidth: 1,
                                                                borderColor: '#fcfbfa',
                                                                borderRadius: 2,
                                                                padding: 2,
                                                                formatter: function (value) {
                                                                    return (
                                                                        (
                                                                            (value * 100) /
                                                                            (el.stronglyDisagree +
                                                                                el.disagree +
                                                                                el.neutral +
                                                                                el.agree +
                                                                                el.stronglyAgree)
                                                                        ).toFixed(0) + '%'
                                                                    );
                                                                },
                                                                font: function (context) {
                                                                    const size = 12;
                                                                    return {
                                                                        size: size,
                                                                        weight: 600,
                                                                    };
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {page.openQuestions.length > 0 ? (
                                <div>
                                    <div className="question survey-title">Open-ended Questions:</div>
                                </div>
                            ) : null}
                            <div className="survey-container answers">
                                {page.openQuestions.map((el, index) => (
                                    <div className="survey survey-open" key={index}>
                                        <div className="survey-question">{el.question}</div>
                                        {el.answers.length > 0 ? (
                                            <div>
                                                {el.answers.map((answ, index) => (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            margin: '20px 0 0',
                                                        }}
                                                        key={index}
                                                    >
                                                        <div
                                                            className="icon-wrapper"
                                                            style={{ backgroundColor: '#53636d' }}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faQuoteRight}
                                                                className="icon"
                                                            />
                                                        </div>
                                                        <div className="answ">{answ}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            'Nobody has answered this question yet.'
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="footer">
                            Powered by <Link to="/">feedbee</Link>.
                        </div>
                    </div>
                ) : (
                    <NotFound />
                )}
            </main>
        );
    }

    _getPage = () => {
        return new Promise((resolve, reject) => {
            api.get(`/api/page/getOneBySlug/${this.props.slug}`)
                .then((data) => {
                    this.setState({
                        page: data.page,
                    });

                    resolve(data.page);
                })
                .catch((err) => {
                    reject(err.description);
                });
        });
    };

    // _getUser = () => {
    //     return new Promise((resolve, reject) => {
    //         api.get(`/api/page/getUser/${this.state.page.user}`)
    //             .then((data) => {
    //                 this.setState({
    //                     isSubscribed: data.isSubscribed,
    //                 });

    //                 resolve(data.isSubscribed);
    //             })
    //             .catch((err) => {
    //                 reject(err.description);
    //             });
    //     });
    // };

    _countTotal(a, b) {
        return a + b;
    }

    _countClosedTotal(a, b, c, d, e) {
        return a + b + c + d + e;
    }

    _countPercentage(a, b, num) {
        return Math.round((num / (a + b)) * 100);
    }
}

export default Feedback;
