import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import check from '../assets/check-img.png';

class Pricing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            USD: '',
            GBP: '',
            EUR: '34.99',
            currentCurrency: 'EUR',
        };
    }

    componentDidMount() {
        this._getExchangeRates();
    }

    render() {
        return (
            <main className="pricing-container container">
                <Helmet>
                    <title>Pricing | feedbee</title>
                </Helmet>

                <div className="pricing-wrapper">
                    <div>
                        <div className="txt-big">Here is what you get:</div>
                        <ul>
                            <li>
                                <img src={check} className="check-img" />
                                <div>
                                    feedback page creator where you can write your open-ended,
                                    close-ended and yes/no questions
                                </div>
                            </li>
                            <li>
                                <img src={check} className="check-img" />
                                <div>five different themes that you can fully personalize</div>
                            </li>
                            <li>
                                <img src={check} className="check-img" />
                                <div>visual presentation of gathered feedback</div>
                            </li>
                            <li>
                                <img src={check} className="check-img" />
                                <div>
                                    anonymous result page that you can share with whomever you wish
                                </div>
                            </li>
                            <li>
                                <img src={check} className="check-img" />
                                <div>downloadable PDF report for every feedback page you create</div>
                            </li>
                        </ul>
                    </div>
                    <div className="pricing">
                        <div className="price-container">
                            <div className="circle circle-color"></div>
                            <div className="circle">
                                <div className="price">
                                    {this.state.currentCurrency === 'EUR'
                                        ? this.state.EUR
                                        : this.state.currentCurrency === 'USD'
                                        ? this.state.USD
                                        : this.state.GBP}
                                    <br />
                                    <div style={{ fontSize: '20px', lineHeight: '15px' }}>
                                        {this.state.currentCurrency === 'EUR'
                                            ? '€'
                                            : this.state.currentCurrency === 'USD'
                                            ? '$'
                                            : '£'}
                                        /year
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '12px',
                                            marginTop: '10px',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        {/* less than 3 */}
                                        {this.state.currentCurrency === 'EUR' && this.state.EUR < 36
                                            ? 'less than 3'
                                            : this.state.currentCurrency === 'USD' && this.state.USD < 36
                                            ? 'less than 3'
                                            : this.state.currentCurrency === 'GBP' && this.state.GBP < 36
                                            ? 'less than 3'
                                            : null}
                                        {this.state.currentCurrency === 'USD' && this.state.USD >= 36
                                            ? 'just around 3'
                                            : this.state.currentCurrency === 'GBP' &&
                                              this.state.GBP >= 36
                                            ? 'just around 3'
                                            : null}
                                        {this.state.currentCurrency === 'EUR'
                                            ? '€'
                                            : this.state.currentCurrency === 'USD'
                                            ? '$'
                                            : '£'}
                                        /month!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="currencies">
                            <button
                                className="currency-btn"
                                onClick={() => this._handleCurrencyChange('EUR')}
                                style={
                                    this.state.currentCurrency === 'EUR'
                                        ? {
                                              borderBottom: '5px solid #0b0e11',
                                              borderTop: 'none',
                                              borderRight: 'none',
                                              borderLeft: 'none',
                                          }
                                        : { border: 'none' }
                                }
                            >
                                €
                            </button>

                            <button
                                className="currency-btn"
                                onClick={() => this._handleCurrencyChange('USD')}
                                style={
                                    this.state.currentCurrency === 'USD'
                                        ? {
                                              borderBottom: '5px solid #0b0e11',
                                              borderTop: 'none',
                                              borderRight: 'none',
                                              borderLeft: 'none',
                                          }
                                        : { border: 'none' }
                                }
                            >
                                $
                            </button>

                            <button
                                className="currency-btn"
                                onClick={() => this._handleCurrencyChange('GBP')}
                                style={
                                    this.state.currentCurrency === 'GBP'
                                        ? {
                                              borderBottom: '5px solid #0b0e11',
                                              borderTop: 'none',
                                              borderRight: 'none',
                                              borderLeft: 'none',
                                          }
                                        : { border: 'none' }
                                }
                            >
                                £
                            </button>
                        </div>
                        <div className="btn-wrapper">
                            {!this.props.user ? (
                                <Link to="/auth/sign-up">
                                    <button
                                        className="start-btn"
                                        style={{ fontSize: '15px', padding: '10px 35px' }}
                                    >
                                        start using for free
                                        <br />
                                        <div
                                            style={{
                                                fontSize: '12px',
                                                fontWeight: 'normal',
                                                fontFamily: 'Lato, sans-serif',
                                                textTransform: 'lowercase',
                                            }}
                                        >
                                            no credit card required
                                        </div>
                                    </button>
                                </Link>
                            ) : null}
                        </div>

                        <div className="txt">
                            <b>The costs shown in USD and GBP are for information purposes only.</b> All
                            invoices will be issued in EURO and charged by your credit card provider at
                            their current exchange rate.
                        </div>
                    </div>
                </div>
                <div style={{ margin: '30px 0 20px', fontSize: '14px' }}>
                    Do you have payment related questions? Check our{' '}
                    <Link to="/faq" className="link-orange">
                        FAQ
                    </Link>{' '}
                    page.
                </div>
            </main>
        );
    }

    _handleCurrencyChange = (currency) => {
        this.setState({
            currentCurrency: currency,
        });
    };

    _getExchangeRates = () => {
        const options = {
            method: 'GET',
            headers: {
                'X-RapidAPI-Key': '0205d698d1mshf7357cb39caec75p1bf1ddjsn2339d8a4bf03',
                'X-RapidAPI-Host': 'currency-exchange.p.rapidapi.com',
            },
        };

        fetch('https://currency-exchange.p.rapidapi.com/exchange?from=EUR&to=USD&q=1.0', options)
            .then((response) => response.json())
            .then((response) => {
                let USD = (response * 34.99).toFixed(2);

                this.setState({
                    USD,
                });
            })
            .catch((err) => console.error(err));

        fetch('https://currency-exchange.p.rapidapi.com/exchange?from=EUR&to=GBP&q=1.0', options)
            .then((response) => response.json())
            .then((response) => {
                let GBP = (response * 34.99).toFixed(2);

                this.setState({
                    GBP,
                });
            })
            .catch((err) => console.error(err));
    };
}

export default Pricing;
