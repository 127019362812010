import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown,
    faChevronUp,
    faFileExcel,
    faPlus,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import Tilt from 'react-tilt';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import smoothscroll from 'smoothscroll-polyfill';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import imgMiddle from '../assets/img1.png';
import imgTop from '../assets/img2.png';
import check from '../assets/check-img.png';
import imgBottom from '../assets/img-bottom.png';

import api from './utils/api';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            checkbox: false,
            emailSubmitted: false,
            windowWidth: window.innerWidth,
            activeClass: 'top',
        };
    }

    componentDidMount() {
        smoothscroll.polyfill();

        //for measuring the size of the window
        window.addEventListener('resize', this._handleResize);

        //for detecting website scroll
        window.addEventListener('scroll', () => {
            let activeClass = 'bottom';
            if (window.scrollY === 0) {
                activeClass = 'top';
            }

            this.setState({ activeClass });
        });
    }

    componentWillUnMount() {
        window.addEventListener('resize', this._handleResize);
    }

    render() {
        const DOMAIN = `${process.env.DOMAIN}`;
        const cannonical = `${DOMAIN}`;

        return (
            <main className="container">
                <div className="home">
                    <Helmet>
                        <title>feedbee | Gather feedback, learn and grow</title>
                        <link rel="canonical" href={cannonical} />
                    </Helmet>
                    <div className="home-section" id="start">
                        <div className="home-wrapper home-column home-top">
                            <div className="home-left">
                                <h1 className="slogan">
                                    Feedback is a key to improvement. <br />
                                    Ask, learn and grow.
                                </h1>
                                <Link to="/auth/sign-up">
                                    <button className="about-btn">
                                        start using for free
                                        <div className="zigzag"></div>
                                    </button>
                                </Link>
                            </div>
                            <div className="home-right">
                                <img src={imgTop} className={`img-home ${this.state.activeClass}`} />
                            </div>
                        </div>
                        <Link smooth to="/#about" className="scroll-btn">
                            find out more
                            <FontAwesomeIcon icon={faChevronDown} />
                        </Link>
                    </div>
                    <div className="about-section" id="about">
                        <div className="home-wrapper home-column home-top">
                            <div className="home-left">
                                <img src={imgMiddle} className="img-middle" />
                            </div>

                            <div className="home-right">
                                <div className="form-txt-big" style={{ letterSpacing: '1.5px' }}>
                                    Gather opinions, get feedback
                                </div>
                                <div className="form-txt-small">
                                    It doesn't matter if you are an employee, freelancer, self-employed,
                                    business owner, or future entrepreneur feedback is always important.
                                    With FEEDBEE, you can conduct a multi-source assessment and gather
                                    peer feedback that will allow you to see how your performance, ideas,
                                    products, services or behaviour are received by co-workers, employers
                                    and current or potential clients.
                                    <div style={{ marginTop: '10px' }}>
                                        If you need to collect feedback for a non-work-related reason,
                                        you are in the right place too!
                                    </div>
                                    <br />
                                    Don't waste any time - create a personalized feedback page and ask
                                    people what they think.
                                </div>
                                <Link to="/auth/sign-up">
                                    <button className="action-btn">try for free</button>
                                </Link>
                            </div>
                        </div>

                        <Link smooth to="/#details" className="scroll-btn details-btn">
                            details
                            <FontAwesomeIcon icon={faChevronDown} />
                        </Link>
                    </div>
                    <div className="data-section" id="details">
                        <div className="home-wrapper examples-column">
                            <div className="data-left">
                                <div className="form-txt-big" style={{ letterSpacing: '1.5px' }}>
                                    Here is what we offer:
                                </div>

                                <div className="feature">
                                    <img src={check} className="check-img" />
                                    <div>
                                        feedback page creator where you can write your open-ended,
                                        close-ended and yes/no questions
                                    </div>
                                </div>

                                <div className="feature">
                                    <img src={check} className="check-img" />
                                    <div>
                                        five themes that you can personalize to match your individual
                                        preferences
                                    </div>
                                </div>

                                <div className="feature">
                                    <img src={check} className="check-img" />
                                    <div>
                                        user panel displaying a graphical presentation of the feedback
                                        results
                                    </div>
                                </div>

                                <div className="feature">
                                    <img src={check} className="check-img" />
                                    <div>
                                        anonymous result page that you can share with whomever you wish
                                    </div>
                                </div>

                                <div className="feature">
                                    <img src={check} className="check-img" />
                                    <div>downloadable PDF report for every feedback page you create</div>
                                </div>

                                {/* <img src={imgBottom} className="img-bottom" /> */}
                            </div>

                            <div className="data-right">
                                <div className="form data-form">
                                    <div className="form-txt-big" style={{ fontSize: '22px' }}>
                                        Feel like being informed?
                                    </div>
                                    <div className="form-txt-small">
                                        Submit your email to join our newsletter. <br />
                                        We will let you know about new features and other exciting
                                        developments.
                                    </div>
                                    <form onSubmit={(e) => this._handleSubmit(e)}>
                                        <input
                                            type="email"
                                            placeholder="email address"
                                            value={this.state.email}
                                            onChange={this._handleChange}
                                            className="email-input"
                                            required
                                        />
                                        <input
                                            type="submit"
                                            value="join"
                                            className="form-btn safari-btn"
                                        />
                                        <br />
                                        <label className="checkbox-label checkbox path">
                                            <input
                                                type="checkbox"
                                                checked={this.state.checkbox}
                                                onChange={this._handleCheckbox}
                                            />
                                            <svg viewBox="0 0 21 21">
                                                <path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
                                            </svg>
                                            I accept
                                            <Link
                                                to="/terms"
                                                className="home-link"
                                                style={{ marginRight: '3px' }}
                                            >
                                                Terms
                                            </Link>
                                            and
                                            <Link to="/privacy-policy" className="home-link">
                                                Privacy Policy
                                            </Link>
                                            .
                                        </label>
                                    </form>
                                    {this.state.emailSubmitted ? (
                                        <div className="privacy-txt success-txt">
                                            Thanks for subscribing!
                                        </div>
                                    ) : null}
                                    {this.state.error ? (
                                        <div className="privacy-txt">{this.state.error}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <Link smooth to="/#start" className="scroll-btn top-btn">
                            <FontAwesomeIcon icon={faChevronUp} />
                            TOP
                        </Link>
                    </div>
                </div>
                <div className="rotate">
                    <div className="phone"></div>
                    <div className="message">please rotate your device</div>
                </div>
            </main>
        );
    }

    _handleChange = (e) => {
        this.setState({ email: e.target.value, error: '' });
    };

    _handleSubmit = (e) => {
        if (this.state.checkbox === true) {
            let email = { email: this.state.email };

            api.post('/api/emails/add', email)
                .then((data) => {
                    this.setState({
                        email: '',
                        error: '',
                        checkbox: false,
                        emailSubmitted: true,
                    });

                    setTimeout(() => {
                        this.setState({
                            emailSubmitted: false,
                        });
                    }, 5000);
                })
                .catch((err) => {
                    this.setState({
                        error: err.description,
                    });
                });
        } else {
            this.setState({ error: 'You have to accept our Terms and Privacy Policy.' });
        }

        e.preventDefault();
    };

    _handleCheckbox = () => {
        this.setState((prevState) => ({
            checkbox: !prevState.checkbox,
            error: '',
        }));
    };

    _handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
    };
}

export default Home;
