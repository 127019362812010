import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const Message = ({ message, isSuccessful, isReady }) => (
    <div className="contact-container checkout">
        <div className="circle-wrapper">
            {isSuccessful && isReady ? <div className="success-circle"></div> : null}
            {!isSuccessful && isReady ? <div className="success-circle cancel-circle"></div> : null}
        </div>
        <div className="checkout-txt-big">{message}</div>
        {isSuccessful ? (
            <div className="checkout-txt-small">Login now to start collecting feedback.</div>
        ) : (
            <div className="checkout-txt-small">Please login to add a valid payment method.</div>
        )}
        <div className="btn-wrapper">
            <Link to="/auth/sign-in">
                <button className="start-btn">login</button>
            </Link>
        </div>
    </div>
);

export default function Checkout(props) {
    const [message, setMessage] = useState('');
    const [isSuccessful, setIsSuccessful] = useState('');
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        const success = query.get('success');
        const sessionId = query.get('session_id');
        if (success && sessionId.length > 0) {
            setMessage('Your account was created successfully.');
            setIsSuccessful(true);
            props.success(sessionId);
        }
        if (query.get('canceled')) {
            setMessage('Your payment method could not be processed.');
            setIsSuccessful(false);
            props.cancel();
        }
        //delay animation
        const timer = setTimeout(() => {
            setIsReady(true);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    return <Message message={message} isSuccessful={isSuccessful} isReady={isReady} />;
}
