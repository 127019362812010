import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import { Redirect, withRouter } from 'react-router-dom';
import Moment from 'react-moment';
import { loadStripe } from '@stripe/stripe-js';
import api from '../utils/api';

const stripePromise = loadStripe(process.env.STRIPE_API_KEY);

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            delete: false,
            error: '',
            password: '',
        };
    }

    componentDidMount() {
        this._refreshUser();

        //refreshing subscription and then user to be sure that status is correct
        this._refreshSubscription();
    }

    render() {
        if (!this.props.user && !this.state.delete) return <Redirect to="/auth/sign-in" />;

        const user = this.props.user;

        return (
            <main className="account-container container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Account Settings | feedbee</title>
                </Helmet>
                <div className="title">Account Settings</div>
                <div>
                    <div className="header">Personal details</div>
                    <div>
                        <b>Name and surname: </b>
                        <span style={{ textTransform: 'capitalize' }}>
                            {user.name} {user.surname}
                        </span>
                    </div>
                    <div>
                        <b>Email: </b>
                        <span style={{ textTransform: 'lowercase' }}>{user.email}</span>
                    </div>
                </div>
                <div>
                    <div className="header" style={{ marginBottom: '3px' }}>
                        Payments
                    </div>
                    <div>
                        <div className="sub-status">
                            {user.isSubscribed &&
                            user.isComplete &&
                            !user.subscription.subscription_will_end
                                ? 'You are currently subscribed'
                                : null}
                            {!user.isSubscribed ? 'You are currently unsubscribed' : null}
                            {user.isComplete && user.subscription.subscription_will_end ? (
                                <span>
                                    Your subscription has been cancelled and will expire on{' '}
                                    <Moment format="DD MMMM YYYY">
                                        {user.subscription.current_period_end}
                                    </Moment>
                                </span>
                            ) : null}
                            .
                        </div>
                    </div>
                    {user.isSubscribed &&
                    !user.subscription.subscription_will_end ? (
                        <div style={{ marginTop: '5px' }}>
                            <div>
                                <b>Payment:</b> 34.99€/year
                            </div>
                            <div className="info-small">
                                The above amount will be charged on{' '}
                                <b>
                                    <Moment format="DD MMMM YYYY">
                                        {user.subscription.subscriptionEndDate}
                                    </Moment>
                                </b>
                                .
                            </div>
                        </div>
                    ) : null}

                </div>

                <div className="btns">
                    {!this.props.user.isComplete ? null : (
                        <button
                            className="payment-btn resign-btn manage-btn"
                            onClick={this._handleSubscriptionClick}
                        >
                            manage your subscription
                        </button>
                    )}

                    {!this.props.user.isSubscribed && !this.props.user.isComplete ? (
                        <button className="add-payment" onClick={this._completePayment}>
                            add payment method
                        </button>
                    ) : null}

                    {!this.state.delete ? (
                        <button className="payment-btn resign-btn" onClick={this._handleDeleteClick}>
                            delete account
                        </button>
                    ) : null}
                </div>

                {this.state.delete ? (
                    <div className="delete-wrapper">
                        <div>
                            If you are sure you want to delete your account, please enter your password
                            below and confirm your choice.
                        </div>
                        <input
                            className="safari-input-btn"
                            type="password"
                            placeholder="password"
                            value={this.state.password}
                            onChange={(e) => this._handleInputChange(e.target.value)}
                            required
                        />
                        <button className="payment-btn resign-btn" onClick={this._cancelSubscription}>
                            delete
                        </button>
                        <div className="delete-error">{this.state.error}</div>
                    </div>
                ) : null}

                <div>
                    <div className="header" style={{ marginBottom: '3px', paddingTop: '20px' }}>
                        FAQ
                    </div>
                    <div className="acc-faq-container">
                        <div className="acc-faq-wrapper">
                            <div className="faq-question">What happens if I unsubscribe?</div>
                            <div>
                                Your subscription will be cancelled at the end of the billing period and
                                all your pages will be unpublished.{' '}
                                <b>You can reactivate your account at any time.</b>
                            </div>
                        </div>
                        <div className="acc-faq-wrapper">
                            <div className="faq-question">What happens if I delete my account?</div>
                            <div>
                                Your subscription will be cancelled, user data and all the pages you've
                                created will be removed immediately.{' '}
                                <b>Deletion of the account cannot be reverted.</b>
                            </div>
                        </div>
                        <div className="acc-faq-wrapper">
                            <div className="faq-question">Where can I find my invoice?</div>
                            <div>
                                To download your invoice, click on the{' '}
                                <b>manage your subscription button</b> above. All your invoices are
                                available in the INVOICE HISTORY section.
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '30px' }}>
                        Answers to other FAQs can be found{' '}
                        <Link className="link faq-link" to="/faq">
                            here.
                        </Link>
                    </div>
                </div>
            </main>
        );
    }

    _completePayment = () => {
        api.post('/api/auth/complete-payment').then((data) => {
            let sessionId = data.session;
            this._handleStripeCheckout(sessionId);
        });
    };

    _handleStripeCheckout = (sessionId) => {
        stripePromise.then((stripe) => {
            stripe.redirectToCheckout({
                sessionId: sessionId,
            });
        });
    };

    _cancelSubscription = () => {
        if (this.props.user.stripeCustomerId) {
            api.post('/api/payment/unsubscribe')
                .then((data) => {
                    this._deleteAccount();
                })
                .catch((err) => {
                    // this.setState({
                    //     error: err.description,
                    // });
                    this._deleteAccount();
                });
        } else {
            this._deleteAccount();
        }
    };

    _deleteAccount = () => {
        this.setState({
            error: '',
        });

        let password;

        if (this.state.password) {
            password = { password: this.state.password };

            api.post('/api/auth/delete', password)
                .then((data) => {
                    this.props.history.push('/account-deleted');

                    localStorage.removeItem('identity');
                    this.props.resetUser();
                })
                .catch((err) => {
                    this.setState({
                        error: err.description,
                    });
                });
        } else {
            this.setState({
                error: 'Please provide your password.',
            });
        }
    };

    _handleInputChange = (newValue) => {
        this.setState({
            password: newValue,
        });
    };

    _handleDeleteClick = () => {
        let currentDelete = this.state.delete;

        this.setState({
            error: '',
            unsubscribe: !currentDelete ? false : true,
            delete: !this.state.delete,
        });
    };

    _handleSubscriptionClick = () => {
        api.post('/api/payment/customer-portal')
            .then((data) => {
                const redirect = window.open(data.portal_url, '_self');
                if (redirect) redirect.opener = null;
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _refreshUser = () => {
        api.post('/api/auth/refresh')
            .then((data) => {
                localStorage.setItem('identity', data.token);
                this.props.setUser();
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });
    };

    _refreshSubscription = () => {
        api.post('/api/payment/refresh-subscription').then((data) => {
            this._refreshUser();
        });
    };
}

export default withRouter(Account);
