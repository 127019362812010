import React from 'react';
import { Redirect } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';
import api from '../utils/api';

class RecoveryRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            error: '',
            sent: false,
        };
    }

    render() {
        if (this.props.user) return <Redirect to="/dashboard" />;

        return (
            <main className="contact-container container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Password Recovery | feedbee</title>
                </Helmet>
                {!this.state.sent ? (
                    <div className="contact-form">
                        <div className="contact-txt-big" style={{ marginBottom: '20px' }}>
                            Reset password.
                        </div>
                        <div className="recovery-txt">
                            If you've forgotten your password, enter your email address below, and we'll
                            send you an email with information on how to recover it.
                        </div>
                        <form onSubmit={(e) => this._requestPasswordChange(e)}>
                            <input
                                type="email"
                                placeholder="email address"
                                value={this.props.email}
                                onChange={(e) => this._handleInputChange(e.target.value)}
                                onKeyPress={this._onKeyPress}
                                required
                            />
                            <input
                                type="submit"
                                value="CONTINUE"
                                className="form-btn sign-btn"
                                style={{ fontSize: '16px' }}
                            />
                        </form>
                        <div className="circle-sign"></div>
                        <div className="circle2-sign"></div>
                    </div>
                ) : (
                    <div className="contact-form" style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="circle-wrapper">
                            <div className="success-circle"></div>
                        </div>
                        <div className="contact-txt-big" style={{ margin: '15px 0 20px' }}>
                            Done.
                        </div>
                        <div className="recovery-txt" style={{ textAlign: 'center' }}>
                            Please check your inbox. If the email address you've provided is registered,
                            you will get an email with instructions on how to reset your password.
                        </div>
                    </div>
                )}
            </main>
        );
    }

    _handleInputChange = (newValue) => {
        this.setState({
            email: newValue,
        });
    };

    _requestPasswordChange = (e) => {
        let email = this.state.email.toLowerCase();

        api.post('/api/auth/request-password-reset', { email })
            .then((data) => {
                this.setState({
                    sent: true,
                });
            })
            .catch((err) => {
                this.setState({
                    error: err.description,
                });
            });

        e.preventDefault();
    };
}

export default RecoveryRequest;
