import React from 'react';
import { Helmet } from 'react-helmet';
import { HashLink as Link } from 'react-router-hash-link';
import check from '../assets/check-img.png';

const Faq = () => {
    return (
        <main className="contact-container imprint-container container">
            <Helmet>
                <title>Faq | feedbee</title>
            </Helmet>
            <div className="contact-form faq-wrapper">
                <div className="contact-txt-big" style={{ alignSelf: 'flex-start' }}>
                    Faq.
                </div>

                <ul>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>What is the cost of using your service?</div>
                            <div className="faq-answ">
                                We offer a yearly subscription plan for 34.99€. All details are available
                                on our{' '}
                                <Link to="/pricing" className="link-orange">
                                    pricing page
                                </Link>
                                .
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>Do you offer a trial period?</div>
                            <div className="faq-answ">
                                No, but you can test our service for free.{' '}
                                <b>No credit card is required.</b>
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>How am I billed?</div>
                            <div className="faq-answ">
                                You are billed <b>yearly</b> using the provided payment method. The
                                payment will be automatically charged at the beginning of each billing
                                period unless you cancel your subscription.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>Can I be billed monthly?</div>
                            <div className="faq-answ">No. We offer only yearly subscription plans.</div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>I want to cancel my subscription. Can I get a refund?</div>
                            <div className="faq-answ">
                                No. We don't offer any refunds for cancelled subscriptions.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>Will I be able to use the service when I cancel my subscription?</div>
                            <div className="faq-answ">
                                Yes. When you cancel your subscription, you will be able to use the
                                service until the end of the current billing period.
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>What will happen if I delete my account?</div>
                            <div className="faq-answ">
                                If you delete your account, all data associated with that account will be
                                deleted. If you are a paid subscriber, your subscription will be
                                cancelled immediately. You will not be able to use the service anymore.{' '}
                                <b>The deletion process is not reversible.</b>
                            </div>
                        </div>
                    </li>
                    <li>
                        <img src={check} className="check-img" />
                        <div className="faq-question">
                            <div>I have a feature idea, can I share it with you?</div>
                            <div className="faq-answ">
                                Of course! We are always interested in improving and developing our
                                service so it best fits our customers' needs. Feel free to contact us
                                using our{' '}
                                <Link to="/contact" className="link-orange">
                                    contact form
                                </Link>
                                .
                            </div>
                        </div>
                    </li>
                </ul>
                <div>
                    Want to learn more? Check out our{' '}
                    <Link to="/about" className="link-orange">
                        about
                    </Link>{' '}
                    page and{' '}
                    <Link to="/blog" className="link-orange">
                        blog
                    </Link>{' '}
                    section!
                </div>
            </div>
        </main>
    );
};

export default Faq;
