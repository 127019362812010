import React from 'react';
import { Redirect } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

class SignIn extends React.Component {
    componentDidMount() {
        this.props.handleInputChange('email', '');
        this.props.handleInputChange('password', '');
    }

    render() {
        if (this.props.user) return <Redirect to="/dashboard" />;

        return (
            <main className="contact-container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Sign In | feedbee</title>
                </Helmet>
                <div className="contact-form">
                    <div className="contact-txt-big" style={{ marginBottom: '20px' }}>
                        Login.
                    </div>
                    <input
                        type="email"
                        placeholder="email address"
                        value={this.props.email}
                        onChange={(e) => this.props.handleInputChange('email', e.target.value)}
                        onKeyPress={this._onKeyPress}
                        required
                    />
                    <input
                        type="password"
                        placeholder="password"
                        value={this.props.password}
                        onChange={(e) => this.props.handleInputChange('password', e.target.value)}
                        onKeyPress={this._onKeyPress}
                        required
                    />
                    <button className="form-btn sign-btn" onClick={() => this.props.sign()}>
                        login
                    </button>
                    {this.props.error ? <div className="sign-error">{this.props.error}</div> : null}
                    <div className="form-info" style={{ fontSize: '14px' }}>
                        <Link to="/auth/recover" className="home-link">
                            Have you forgotten your password?
                        </Link>
                    </div>
                    <div className="form-info" style={{ marginTop: '3px', fontSize: '14px' }}>
                        Don't have an account yet?{' '}
                        <Link to="/auth/sign-up" className="home-link">
                            Sign up
                        </Link>
                        .
                    </div>
                    {/* <div className="circle-sign"></div>
                    <div className="circle2-sign"></div> */}
                </div>
            </main>
        );
    }

    _onKeyPress = (e) => {
        if (e.which === 13) {
            this.props.sign();
        }
    };
}

export default SignIn;
