import React from 'react';
import ReactTilt from 'react-universal-tilt';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet';

class SignIn extends React.Component {
    render() {
        return (
            <main className="contact-container imprint-container container">
                <Helmet>
                    <meta name="robots" content="noindex" />
                    <title>Imprint | feedbee</title>
                </Helmet>
                <div className="contact-form">
                    <div className="contact-txt-big" style={{ marginBottom: '20px' }}>
                        Imprint.
                    </div>
                    <div className="imprint-txt">Angaben gemäß § 5 TMG</div>
                    <br />
                    <div className="company-big">feedbee.co</div>
                    <div className="company-small">Katarzyna Roszak</div>
                    <br />
                    <div>
                        Kantstraße 65
                        <br />
                        10627, Berlin, Germany
                    </div>
                    <br />
                    <div className="imprint-contact">
                        contact: <a href="mailto:info@feedbee.co">info@feedbee.co</a>
                    </div>
                    <br />
                    <div className="imprint-txt">
                        Umsatzsteuer-ID nach § 27a Umsatzsteuergesetz: DE350505545
                    </div>
                    <div className="imprint-txt">
                        Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV: Katarzyna Roszak
                    </div>
                    {/* <div className="imprint-circle"></div>
                    <div className="imprint-circle2"></div> */}
                </div>
            </main>
        );
    }
}

export default SignIn;
