import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, withRouter, useRouteMatch } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import api from './utils/api';
import Auth from './Auth';
import Home from './Home';
import Navigation from './Navigation';
import Dashboard from './Profile/index';
import NotFound from './NotFound';
import Footer from './Footer';
import Create from './Create';
import Edit from './Edit';
import Pricing from './Pricing';
import Details from './Profile/Details';
import Account from './Profile/Account';
import Feedback from './Profile/Feedback';
import Templates from './Templates/Templates';
import Imprint from './Imprint';
import Terms from './Terms';
import Privacy from './Privacy';
import Faq from './Faq';
import About from './About';
import Contact from './Contact';
import Blog from './Blog/Blog';
import Article from './Blog/Article';
import AccountDeleted from './Profile/AccountDeleted';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

const Application = ({}) => {
    const [user, setUser] = useState(_setUser(true));

    useEffect(() => {
        _setUser();
    }, []);

    const match = useRouteMatch(['/p/:id', '/f/:id', '/theme/:template']);

    return (
        <div>
            <Route>
                <div style={{ position: 'relative', minHeight: '100vh' }}>
                    {(match && match.path === '/p/:id') || (match && match.path === '/f/:id') ? null : (
                        <Navigation user={user} />
                    )}

                    <Switch>
                        <Route exact path="/" render={() => <Home user={user} />} />
                        <Route
                            exact
                            path="/dashboard"
                            render={() => <Dashboard user={user} setUser={_setUser} />}
                        />
                        <Route
                            exact
                            path="/account"
                            render={() => (
                                <Account user={user} setUser={_setUser} resetUser={_resetUser} />
                            )}
                        />
                        <Route exact path="/account-deleted" render={() => <AccountDeleted />} />
                        <Route
                            exact
                            path="/dashboard/:id"
                            render={({ match }) => <Details id={match.params.id} user={user} />}
                        />
                        <Route
                            exact
                            path="/edit/:id"
                            render={({ match }) => <Edit id={match.params.id} user={user} />}
                        />
                        <Route
                            exact
                            path="/f/:slug"
                            render={({ match }) => <Feedback slug={match.params.slug} />}
                        />
                        <Route
                            exact
                            path="/p/:id"
                            render={({ match }) => <Templates id={match.params.id} />}
                        />
                        <Route exact path="/create" render={() => <Create user={user} />} />
                        <Route exact path="/pricing" render={() => <Pricing user={user} />} />
                        <Route
                            path="/auth"
                            render={() => <Auth setUser={_setUser} resetUser={_resetUser} user={user} />}
                        />
                        <Route exact path="/blog" component={Blog} />
                        <Route
                            exact
                            path="/blog/:title"
                            render={({ match }) => <Article title={match.params.title} />}
                        />
                        <Route exact path="/about" render={() => <About user={user} />} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/imprint" component={Imprint} />
                        <Route exact path="/privacy-policy" component={Privacy} />
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/faq" component={Faq} />
                        <Route component={NotFound} />
                    </Switch>
                    {(match && match.path === '/p/:id') ||
                    (match && match.path === '/f/:id') ||
                    (match && match.path === '/theme/:template') ? null : (
                        <Footer />
                    )}
                </div>
            </Route>
        </div>
    );

    function _resetUser() {
        setUser(null);
    }

    function _setUser(init) {
        const token = localStorage.getItem('identity');
        if (token) {
            const decoded = jwtDecode(token);
            delete decoded.iat;
            if (init) return decoded;
            setUser(decoded);
        } else {
            return null;
        }
    }
};

export default Application;
